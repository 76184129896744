import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from 'utils/styleHelpers'
import { genSVGId } from 'utils/genGuid'

const InventoryEdibleIcon = ({ width, height, color }) => {
	const svgId1 = genSVGId()
	const svgId2 = genSVGId()
	const svgId3 = genSVGId()
	const svgId4 = genSVGId()

	return (
		<div>
			<svg
				height={height}
				viewBox="0 0 40 40"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
			>
				<defs>
					<path
						d="M20.77 7.33L21.26 8.49L22.02 9.47L23 10.22L24.15 10.71L25.44 10.89L25.49 10.88L25.72 12.02L26.47
				13.14L27.59 13.9L28.96 14.17L30.32 13.9L31.44 13.14L31.66 12.81L31.83 13.09L32.4 14.26L32.85
				15.5L33.18 16.79L33.39 18.13L33.46 19.5L33.39 20.87L33.18 22.21L32.85 23.5L32.4 24.74L31.83
				25.91L31.16 27.02L30.38 28.06L29.51 29.01L28.56 29.88L27.52 30.66L26.41 31.33L25.24 31.9L24
				32.35L22.71 32.68L21.37 32.89L20 32.96L18.63 32.89L17.29 32.68L16 32.35L14.76 31.9L13.59 31.33L12.48
				30.66L11.44 29.88L10.49 29.01L9.62 28.06L8.84 27.02L8.17 25.91L7.6 24.74L7.15 23.5L6.82 22.21L6.61
				20.87L6.54 19.5L6.61 18.13L6.82 16.79L7.15 15.5L7.6 14.26L8.17 13.09L8.84 11.98L9.62 10.94L10.49
				9.99L11.44 9.12L12.48 8.34L13.59 7.67L14.76 7.1L16 6.65L17.29 6.32L18.63 6.11L20 6.04L20.6
				6.08L20.77 7.33Z"
						id={svgId1}
					/>
					<path
						d="M16.69 16.73C16.49 18.05 15.29 18.96 14.02 18.77C12.75 18.57 11.88 17.34 12.08
				16.03C12.28 14.71 13.48 13.8 14.75 13.99C16.02 14.18 16.89 15.41 16.69 16.73Z"
						id={svgId2}
					/>
					<path
						d="M22.17 25.88C22.17 27.21 21.2 28.3 20 28.3C18.8 28.3 17.83 27.21 17.83 25.88C17.83
				24.54 18.8 23.46 20 23.46C21.2 23.46 22.17 24.54 22.17 25.88Z"
						id={svgId3}
					/>
					<path
						d="M28.41 18.75C28.41 20.06 27.37 21.13 26.08 21.13C24.79 21.13 23.74 20.06 23.74 18.75C23.74
				17.44 24.79 16.38 26.08 16.38C27.37 16.38 28.41 17.44 28.41 18.75Z"
						id={svgId4}
					/>
				</defs>
				<use fill={color} xlinkHref={`#${svgId1}`} />
				<use fillOpacity="0" stroke={COLORS.GREEN} xlinkHref={`#${svgId1}`} />
				<use fill={COLORS.WHITE} xlinkHref={`#${svgId2}`} />
				<use fill={COLORS.WHITE} xlinkHref={`#${svgId3}`} />
				<g>
					<use fill={COLORS.WHITE} xlinkHref={`#${svgId4}`} />
				</g>
			</svg>
		</div>
	)
}

InventoryEdibleIcon.propTypes = {
	color: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
}
InventoryEdibleIcon.defaultProps = {
	color: COLORS.GREEN,
	height: 40,
	width: 40,
}
export default InventoryEdibleIcon
