import propTypes from 'prop-types'
import React from 'react'
import { COLORS } from 'utils/styleHelpers'
import {
	InventoryFlowerIcon,
	InventoryConcentrateIcon,
	InventoryEdibleIcon,
	DispensaryLocationIcon,
	StrainIcon,
} from '.'

const INVENTORY_ICONS_MAP = {
	concentrates: InventoryConcentrateIcon,
	concentrate: InventoryConcentrateIcon,
	edibles: InventoryEdibleIcon,
	edible: InventoryEdibleIcon,
	flower: InventoryFlowerIcon,
	other: InventoryConcentrateIcon,
	'pre-rolls': InventoryFlowerIcon,
	clones: InventoryFlowerIcon,
	seeds: InventoryFlowerIcon,
}

const getIconByIndexAndCategory = (index = '', category = '') => {
	const normalizedIndex = index.toLowerCase()
	const normalizedCategory = category.toLowerCase()

	if (normalizedIndex === 'dispensaries') {
		return DispensaryLocationIcon
	}
	if (normalizedIndex === 'strains') {
		return StrainIcon
	}
	if (normalizedIndex === 'inventory' && normalizedCategory) {
		return INVENTORY_ICONS_MAP[normalizedCategory] ? INVENTORY_ICONS_MAP[normalizedCategory] : InventoryFlowerIcon
	}
	return InventoryFlowerIcon
}

const SelectTaxonomyIcon = props => {
	const { index, category } = props

	const IconComponent = getIconByIndexAndCategory(index, category)

	return <IconComponent {...props} />
}

SelectTaxonomyIcon.propTypes = {
	category: propTypes.string,
	color: propTypes.string,
	height: propTypes.number,
	index: propTypes.string,
	width: propTypes.number,
}

SelectTaxonomyIcon.defaultProps = {
	height: 40,
	width: 40,
	color: COLORS.GREEN,
}

export default SelectTaxonomyIcon
