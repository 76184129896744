import styled from '@emotion/styled'
import { MQ, FONT_SIZES, SPACERS, COLORS } from 'utils/styleHelpers'
import { Flex } from '@rebass/emotion'
import SearchFilter from './SearchFilter'
import { css } from '@emotion/core'

export const ResetButton = styled.button`
	background-color: transparent;
	border: 1px solid transparent;
	cursor: pointer;
	display: inline-block;
	margin: auto;
	outline: 0;
	position: absolute;
	right: 2px;
	top: 4px;
	vertical-align: middle;
	${MQ.L} {
		top: 15px;
		right: 5px;
	}
`

export const SearchBox = styled.input`
	appearance: none;
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
	border-bottom-style: hidden;
	border-radius: 5px 5px 0 0;
	color: ${COLORS.DARK_GRAY};
	font-size: ${FONT_SIZES.H5};
	outline: none;
	padding: ${SPACERS.M};
	position: relative;
	width: 100%;
	transition: background 0.5s, color 0.5s;
	&:not(:valid) ~ ${ResetButton} {
		display: none;
	}
	::placeholder {
		color: ${COLORS.MEDIUM_GRAY};
	}
	${MQ.L} {
		font-size: ${FONT_SIZES.H4};
		color: ${COLORS.GRAY};
		::placeholder {
			font-size: ${FONT_SIZES.H4};
		}
		height: 60px;
		border-radius: 5px 5px 5px 5px;
	}
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
		color: ${COLORS.GRAY};
		::placeholder {
			color: ${COLORS.MEDIUM_LIGHT_GRAY};
		}
		${MQ.L} {
			::placeholder {
				color: ${COLORS.MEDIUM_GRAY};
			}
			color: ${COLORS.GRAY};
			background: ${COLORS.WHITE};
		}
	}
`
export const SearchFormWrapper = styled(Flex)`
	margin-top: 9px;
`
export const SearchFilterWrapper = styled(Flex)`
	margin: 0;
`
export const SearchFilterBox = styled(SearchFilter)`
	align-self: center;
	${MQ.L} {
		align-self: flex-start;
		border: 3px solid yellow;
	}
	${MQ.M} {
		align-self: flex-start;
		border: 3px solid yellow;
	}
`

export const SearchWrapper = styled.div`
	position: ${({ position }) => (!position ? 'relative' : position)};
	margin: 0 auto;
	max-width: 568px;

	form {
		position: relative;
		margin-bottom: 0;
	}
	${MQ.L} {
		max-width: 800px;
	}
`

export const ResultsWrapper = styled.div`
	border-radius: 0 0 5px 5px;
	margin: 0px;
	overflow: hidden;
	position: ${({ resultsPosition }) => (!resultsPosition ? 'relative' : resultsPosition)};
	width: 100%;
	z-index: 1;
	border: ${({ isShowingResults }) => (!isShowingResults ? 'none' : `1px solid ${COLORS.LIGHT_GRAY}`)};
	.dark & {
		border: ${({ isShowingResults }) => (!isShowingResults ? 'none' : `1px solid ${COLORS.DARK_GRAY}`)};
	}
	${MQ.L} {
		border-radius: 5px 5px 5px 5px;
		border: ${({ isShowingResults }) => (!isShowingResults ? 'none' : `1px solid ${COLORS.LIGHT_GRAY}`)};
		margin-top: ${SPACERS.M};
		transition: border 0.5s;
		.dark & {
			border: ${({ isShowingResults }) => (!isShowingResults ? 'none' : `1px solid ${COLORS.DARK_GRAY}`)};
		}
	}
`
export const ResultItemSuffix = styled.div`
	background: ${COLORS.WHITE};
	padding: 15px;
	text-align: center;
	width: 100%;
	transition: background 0.5s, border-bottom 0.5s;
	.dark & {
		background: ${COLORS.BLACK};
	}
`
const largeFilterItemWrapper = ({ isSelected }) => css`
	align-content: center;
	justify-content: center;
	margin-right: ${SPACERS.S};
	margin-left: ${SPACERS.S};
	margin-top: ${SPACERS.L};
	border: 2px solid ${isSelected ? COLORS.BLACK : COLORS.WHITE};
	background: ${isSelected ? COLORS.BLACK : 'transparent'};
	&:not(:last-child) {
		border-radius: 6px;
	}
	&:first-of-type {
		border-radius: 0 0 0px 3px;
		border-left: 3px solid ${isSelected ? COLORS.BLACK : COLORS.WHITE};
		border-radius: 6px;
	}
	&:last-child {
		border-radius: 0 0 3px 0px;
		border-right: 3px solid ${isSelected ? COLORS.BLACK : COLORS.WHITE};
		border-radius: 6px;
	}
`

export const SearchFilterItemWrapper = styled.div`
	background: ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.SUPER_MEDIUM_GRAY)};
	border-bottom: 1px solid ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.MEDIUM_LIGHT_GRAY)};
	border-right: 1px solid ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.MEDIUM_LIGHT_GRAY)};
	border-top: 1px solid ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.MEDIUM_LIGHT_GRAY)};
	cursor: pointer;
	display: flex;
	flex-wrap: nowrap;
	text-align: center;
	width: 100%;
	&:not(:last-child) {
		border-radius: 0 0 0 0;
	}
	&:first-of-type {
		border-radius: 0 0 0px 3px;
		border-left: 1px solid ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.MEDIUM_LIGHT_GRAY)};
	}
	&:last-child {
		border-radius: 0 0 3px 0px;
		border-right: 1px solid ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.MEDIUM_LIGHT_GRAY)};
	}

	${MQ.L} {
		${largeFilterItemWrapper}
	}
	.dark & {
		background: ${({ isSelected }) => (isSelected ? COLORS.MEDIUM_LIGHT_GRAY : COLORS.DARK_BLACK)};
		border-top: 1px solid ${COLORS.GRAY};
		border-bottom: 1px solid ${COLORS.GRAY};
		border-right: 1px solid ${COLORS.GRAY};
		&:not(:last-child) {
			border-radius: 0 0 0 0;
		}
		&:first-of-type {
			border-radius: 0 0 0px 3px;
			border-left: 1px solid ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.GRAY)};
		}
		&:last-child {
			border-radius: 0 0 3px 0px;
			border-right: 1px solid ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.GRAY)};
		}
		${MQ.L} {
			${largeFilterItemWrapper}
		}
	}
`

export const SearchFilterLabel = styled.div`
	text-align: center;
	font-size: ${FONT_SIZES.H8};
	padding: ${SPACERS.M};
	transition: color 0.5s;
	color: ${({ isSelected }) => (isSelected ? COLORS.WHITE : COLORS.BLACK)};
	width: 100%;
	${MQ.L} {
		color: ${COLORS.WHITE};
		text-align: center;
		font-size: ${FONT_SIZES.H4};
		text-align: center;
		padding-left: ${SPACERS.L};
		padding-right: ${SPACERS.L};
	}
	.dark & {
		color: ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.MEDIUM_LIGHT_GRAY)};
		${MQ.L} {
			color: ${({ isSelected }) => (isSelected ? COLORS.WHITE : COLORS.WHITE)};
		}
	}
`

export const DispensaryWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
`

export const ResultItem = styled.div`
	align-content: stretch;
	background: ${COLORS.WHITE};
	border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding: 10px;
	justify-content: center;
	margin: 0 auto;
	text-align: left;
	transition: background 0.5s, border-top 0.5s, border-bottom 0.5s;
	${MQ.M} {
		font-size: ${FONT_SIZES.H4};
		height: ${({ index }) => (index === 'inventory' || index === 'dispensaries' ? '100px' : '60px')};
	}
	.dark & {
		background: ${COLORS.BLACK};
		border-bottom: 1px solid ${COLORS.DARK_GRAY};
	}
`

export const ResultItemDetails = styled.div`
	align-self: center;
	flex-basis: 30%;
	flex-grow: 1;
`

export const ResultLocationSearchWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: center;
`
export const ResultItemTitle = styled.div`
	align-self: center;
	color: ${COLORS.GRAY};
	flex-grow: 1;
	flex-basis: 40%;
	font-size: ${FONT_SIZES.H6};
	font-weight: bold;
	transition: color 0.5s;
	${MQ.M} {
		font-size: ${FONT_SIZES.H4};
	}
	${MQ.L} {
		&:hover {
			text-decoration: underline;
		}
	}
	.dark & {
		color: ${COLORS.MEDIUM_LIGHT_GRAY};
	}
`

export const ResultItemSubTitle = styled.div`
	color: ${COLORS.GRAY};
	font-size: ${FONT_SIZES.H6};
	transition: color 0.5s;
	${MQ.M} {
		align-self: flex-start;
	}
	${MQ.L} {
		&:hover {
			text-decoration: underline;
		}
		font-size: ${FONT_SIZES.H4};
	}
	.dark & {
		color: ${COLORS.WHITE};
	}
`
export const ResultLocation = styled.div`
	color: ${COLORS.GRAY};
	font-size: ${FONT_SIZES.H6};
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.MEDIUM_LIGHT_GRAY};
	}
	${MQ.M} {
		align-self: flex-start;
		font-size: ${FONT_SIZES.H4};
	}
`
export const ResultDistance = styled.div`
	color: ${COLORS.GREEN};
	font-size: ${FONT_SIZES.H6};
	${MQ.M} {
		align-self: flex-start;
		font-size: ${FONT_SIZES.H4};
	}
`
export const ResultItemIcon = styled.div`
	align-self: center;
	flex-basis: 10%;
	padding-left: ${SPACERS.S};
	padding-right: ${SPACERS.S};
	background-repeat: no-repeat;
`
