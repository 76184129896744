import React, { useContext } from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { EVENT_TYPES, TRACKING_ACTIONS, NODE_TYPES } from 'utils/constants'
import LinkWrapper from 'components/LinkWrapper'
import { SessionContext } from 'components/AuthContext'
function SearchResultTracker({
	children,
	clearSearchState,
	inventoryCategory,
	key,
	nodeCity,
	nodeID,
	nodeLabel,
	nodeState,
	nodeType,
	nodeZip,
	productId = null,
	to
}) {
	const { sessionID, userIp, uid, version } = useContext(SessionContext)
	const onClick = () => {
		clearSearchState()
	}

	return (
		<LinkWrapper
			key={key}
			to={to}
			aria-selected={false}
			css={css`
				cursor: pointer;
			`}
			state={nodeType === NODE_TYPES.INVENTORY ? { productId } : {}}
			tracker={{
				action: TRACKING_ACTIONS.SEARCH_SELECT_ACTION,
				event: EVENT_TYPES.WR_SEARCH_SELECT,
				inventoryCategory: inventoryCategory.toLowerCase(),
				nodeCity,
				nodeID,
				nodeLabel,
				nodeState,
				nodeZip,
				nodeType,
				strainCategory: (nodeType === NODE_TYPES.FLOWER || nodeType === NODE_TYPES.STRAINS) ? inventoryCategory.toLowerCase() : null,
				sessionID,
				userIp,
				uid,
				version,
			}}
			onClick={onClick}
			role="option"
			tabIndex={-1}
		>
			{children}
		</LinkWrapper>
	)
}

SearchResultTracker.propTypes = {
	children: PropTypes.func,
	clearSearchState: PropTypes.func,
	inventoryCategory: PropTypes.string,
	key: PropTypes.string,
	nodeCity: PropTypes.string,
	nodeID: PropTypes.string,
	nodeLabel: PropTypes.string,
	nodeState: PropTypes.string,
	nodeType: PropTypes.string,
	nodeZip: PropTypes.string,
	productId: PropTypes.string,
	to: PropTypes.string.isRequired,
}
export default SearchResultTracker
