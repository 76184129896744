const ToSlugCase = require('to-slug-case')

const getLink = (node, nodeType) => {
	const city = !node.city ? 'not available' : node.city
	if (nodeType === 'dispensaries' || nodeType === 'inventory') {
		// console.log(` :: ${node.city || `not defined`} :: `)
	}
	const link =
		nodeType === 'dispensaries' || nodeType === 'inventory'
			? `/dispensaries/${node.state.toLowerCase()}/${ToSlugCase(city)}/${node.slug}`
			: `/strains/${node.slug}`
	return link.toLowerCase()
}

const getUrlVars = (url, urlParam) => {
	const urlObj = new URL(url)
	return urlObj.searchParams.get(urlParam)
}

module.exports = {
	getLink,
	getUrlVars,
}
