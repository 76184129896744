import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import { COLORS, SPACERS, FONT_SIZES, MQ } from 'utils/styleHelpers'
import { Flex, Box } from '@rebass/emotion'
import SocialLinks from 'components/SocialLinks'
import { getLink } from 'utils/urlHelpers'
import { NODE_TYPES } from 'utils/constants'
import WeedraterLogo from 'components/Header/WeedRaterLogo'
import { Breakpoint } from 'react-socks'
import { FooterHeader, FooterLinkBox, FooterLink, LogoWrapper, CopyrightText } from './styles'

const Footer = () => {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			allDispensariesJson(filter: { dispensary: { showInHome: { eq: true } } }) {
				edges {
					node {
						dispensary {
							showInHome
							id
							name
							imageUrl
							averageRating
							totalReviews
							address1
							address2
							city
							state
							slug
							zip
						}
					}
				}
			}
			allStrainsJson(filter: { strain: { showInHome: { eq: true } } }) {
				edges {
					node {
						strain {
							name
							id
							category
							averageRating
							totalReviews
							description
							slug
							cannabinoidProfile
							imageURL
						}
					}
				}
			}
		}
	`)
	const dispensariesRef = useMemo(() => data.allDispensariesJson.edges.map(({ node }) => ({ ...node.dispensary })))
	const strainsRef = useMemo(() =>
		data.allStrainsJson.edges.map(({ node }) => ({
			...node.strain,
		}))
	)
	return (
		<Flex
			flexDirection={['column', 'row-reverse']}
			justifyContent={['flex-start', 'space-around']}
			css={css`
				background: ${COLORS.BLACK};
				transition: background 0.5s;
				.dark & {
					background: ${COLORS.DARK_BLACK};
				}
			`}
		>
			<Box
				flexBasis="50%"
				css={css`
					padding-top: ${SPACERS.XL};
					margin-top: ${SPACERS.L};
					${MQ.L} {
						padding-top: ${SPACERS.L};
						margin-top: ${SPACERS.L};
					}
				`}
			>
				<Flex flexDirection="row" justifyContent="space-around">
					<Box>
						<Flex flexDirection="column">
							<FooterHeader>Top Strains</FooterHeader>
							{strainsRef.map(strain => {
								const { name, id } = strain
								const link = getLink(strain, NODE_TYPES.STRAINS)
								return (
									<FooterLinkBox key={`footer_dispensary_${id}`}>
										<FooterLink to={link}>{name}</FooterLink>
									</FooterLinkBox>
								)
							})}
						</Flex>
					</Box>
					<Box>
						<Flex flexDirection="column">
							<FooterHeader>Top Dispensaries</FooterHeader>
							{dispensariesRef.map(dispensary => {
								const { name, id } = dispensary
								const link = getLink(dispensary, NODE_TYPES.DISPENSARIES)
								return (
									<FooterLinkBox key={`footer_dispensary_${id}`}>
										<FooterLink to={link}>{name}</FooterLink>
									</FooterLinkBox>
								)
							})}
						</Flex>
					</Box>
				</Flex>
			</Box>
			<Box>
				<Flex flexDirection="column">
					<Box
						css={css`
							margin-left: ${SPACERS.M};
							margin-right: ${SPACERS.M};
							margin-top: ${SPACERS.L};
							${MQ.L} {
								margin-top: ${SPACERS.L};
							}
						`}
					>
						<LogoWrapper>
							<WeedraterLogo color={COLORS.WHITE} textColor={COLORS.WHITE} />
						</LogoWrapper>
					</Box>

					<Box>
						<Breakpoint M up>
							<SocialLinks />
						</Breakpoint>
					</Box>
					<Box
						css={css`
							margin-left: ${SPACERS.M};
							font-size: ${FONT_SIZES.H7};
							color: ${COLORS.GREEN};
						`}
					>
						<Link to="/tos"> Terms of Use</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
					</Box>
					<Box>
						<Flex
							css={css`
								margin: ${SPACERS.M};
							`}
							flexDirection={['row']}
							justifyContent="space-between"
						>
							<Box>
								<CopyrightText>Built with love in Boston, Powered by Gatsby</CopyrightText>
								<CopyrightText>Copyright 2019 All Rights Researved</CopyrightText>
							</Box>
							<Breakpoint S down>
								<Box>
									<SocialLinks />
								</Box>
							</Breakpoint>
						</Flex>
					</Box>
				</Flex>
			</Box>
		</Flex>
	)
}

export default Footer
