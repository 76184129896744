import React, { useContext } from 'react'
import { func, bool, object } from 'prop-types'
import { Link } from 'gatsby'
import { Flex, Box } from '@rebass/emotion'
import UserMenu from 'components/UserMenu'
import WeedRaterLogo from './WeedRaterLogo'
import SearchIcon from './SearchIcon'
import AccountLink from 'components/AccountLink'
import Breadcrumbs from 'components/Breadcrumbs'
import HamburgerArrow from './HamburgerArrow'
import ThemeToggle from 'components/ThemeToggle'
import { SessionContext } from 'components/AuthContext'
import { StickyNav, HeaderBar, BreadcrumbsWrapper, LogoWrapper, HeaderBox, HeaderIconBox } from './styles'
const Header = ({
	resetDrawer,
	location,
	isDisplayingSearch = true,
	isShowingBreadcrumbs = true,
	isShowingAccountLink = false,
	toggleNav,
	toggleSearch,
}) => {
	const { uid, sessionID } = useContext(SessionContext)
	return (
		<StickyNav id="headerBar">
			<Box>
				<HeaderBar>
					<HeaderBox>
						<span>
							<HamburgerArrow onClick={toggleNav} />
						</span>
					</HeaderBox>
					<HeaderBox onClick={resetDrawer}>
						<LogoWrapper>
							<Link to="/">
								<WeedRaterLogo />
							</Link>
						</LogoWrapper>
					</HeaderBox>
					<HeaderBox>
						<span>
							<Flex justifyContent="flex-end" alignItems="center" flexDirection="row">
								{isDisplayingSearch && (
									<HeaderIconBox>
										<SearchIcon onClick={toggleSearch} />
									</HeaderIconBox>
								)}
								{isShowingAccountLink && (
									<HeaderIconBox alignSelf={`center`}>
										<AccountLink />
									</HeaderIconBox>
								)}
								<HeaderIconBox>
									<ThemeToggle />
								</HeaderIconBox>
								{sessionID && (
									<Box>
										<UserMenu sessionID={sessionID} uid={uid} pathname={location.pathname} />
									</Box>
								)}
							</Flex>
						</span>
					</HeaderBox>
				</HeaderBar>
			</Box>
			{isShowingBreadcrumbs && (
				<Box>
					<Flex>
						<BreadcrumbsWrapper>
							<Breadcrumbs location={location} />
						</BreadcrumbsWrapper>
					</Flex>
				</Box>
			)}
		</StickyNav>
	)
}

Header.propTypes = {
	isDisplayingSearch: bool,
	isShowingAccountLink: bool,
	isShowingBreadcrumbs: bool,
	location: object,
	resetDrawer: func,
	toggleNav: func,
	toggleSearch: func,
}

export default Header
