import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import PropTypes from 'prop-types'
import { SPACERS, MQ } from 'utils/styleHelpers'
export const FormWrapper = styled(Flex)`
	justify-content: center;
	width: 100%;
`
export const FormWrapperBox = styled.div`
	width: 80%;
	${MQ.L} {
		width: 40%;
	}
`
export const FormFlexStyle = styled(Flex)``

export const FormFlex = ({ children }) => (
	<FormFlexStyle flexDirection="column" justifyContent="center" width={1}>
		{children}
	</FormFlexStyle>
)

export const FormFlexInner = ({ children }) => (
	<Flex flexWrap="wrap" justifyContent="center">
		{children}
	</Flex>
)
export const FormFlexInnerColumn = ({ children }) => (
	<Flex flexDirection="row" flexWrap="wrap" justifyContent="flex-start">
		{children}
	</Flex>
)

export const FormFlexInnerColumnBox = styled(Box)``

// export const FormBox = ({ children }) => <Box alignSelf="center">{children}</Box>
export const FormBox = styled(Box)`
	width: 100%;
	margin: ${SPACERS.S};
`
export const FormBoxCenter = styled(Box)`
	width: 100%;
	margin: ${SPACERS.S};
	text-align: center;
`
// alignSelf = "center"
FormFlexInnerColumnBox.propTypes = {
	children: PropTypes.node,
}

FormFlex.propTypes = {
	children: PropTypes.node,
}

FormFlexInner.propTypes = {
	children: PropTypes.node,
}
FormFlexInnerColumn.propTypes = {
	children: PropTypes.node,
}

FormBox.propTypes = {
	children: PropTypes.node,
}
