import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from 'utils/styleHelpers'
import { genSVGId } from 'utils/genGuid'

const InventoryFlowerIcon = ({ width, height, color }) => {
	const svgId = genSVGId()
	return (
		<div>
			<svg height={height} viewBox="0 0 40 40" width={width}>
				<defs>
					<path
						d="M10.55 13.09C10.9 13.32 11.01 13.67 11.25 14.01C11.36 14.01 11.36 14.01 11.47 14.01C11.59 14.13
				11.71 14.24 11.82 14.36C11.82 14.47 11.82 14.47 11.82 14.59C11.94 14.59 12.05 14.59 12.05 14.7C12.05
				14.82 12.05 14.93 12.05 15.05C12.28 15.28 12.51 15.16 12.63 15.39C12.63 15.51 12.63 15.51 12.63
				15.63C12.74 15.63 12.86 15.63 12.86 15.74C12.74 15.97 13.09 16.09 12.97 16.32C13.55 16.66 13.66
				17.24 13.89 17.81C14.01 17.81 14.01 17.81 14.12 17.81C14.24 17.93 14.24 17.93 14.35 18.04C14.35
				18.62 14.7 19.19 15.16 19.54C15.16 19.65 15.16 19.65 15.16 19.77C15.27 19.88 15.27 19.88 15.39
				20C15.39 20.35 15.39 20.58 15.62 20.81C15.62 20.92 15.62 21.04 15.62 21.15C15.85 21.15 15.85 21.27
				15.97 21.38C16.08 21.84 16.08 22.53 16.54 22.76C16.54 22.88 16.54 22.99 16.54 23.11C16.89 23.34
				16.66 23.68 16.89 24.03C16.87 24.04 16.78 24.13 16.77 24.14C16.89 24.26 17 24.38 17.12 24.49C17.12
				24.72 17.23 24.95 17.35 25.18C17.34 25.19 17.24 25.28 17.23 25.3C17.23 25.53 17.58 25.3 17.58
				25.53C17.69 25.99 17.81 26.33 17.92 26.79C18.04 26.79 18.15 26.79 18.15 26.91C18.15 27.14 18.15
				27.37 18.15 27.6C18.38 27.71 18.38 27.94 18.38 28.17C18.96 28.86 19.07 29.67 19.53 30.36C19.65 30.02
				19.42 29.56 19.42 29.21C19.19 28.98 19.3 28.63 19.07 28.4C19.09 28.39 19.18 28.3 19.19 28.29C18.84
				27.94 18.96 27.25 18.84 26.79C18.73 26.79 18.61 26.79 18.61 26.68C18.84 26.1 18.38 25.41 18.15
				24.84C18.15 24.72 18.15 24.6 18.27 24.6C18.27 24.49 18.27 24.38 18.15 24.38C18.16 24.36 18.26 24.27
				18.27 24.26C18.15 24.14 18.15 24.14 18.04 24.03C18.04 23.68 17.92 23.45 17.69 23.22C17.69 22.99
				17.92 22.88 17.81 22.65C17.69 22.53 17.69 22.53 17.58 22.42C17.46 22.07 17.58 21.61 17.23
				21.27C17.23 21.15 17.23 21.04 17.35 20.92C17.12 20.35 17 19.65 16.89 19.08C17.12 18.73 16.89 18.16
				16.66 17.81C16.77 17.47 16.31 17.01 16.89 16.89C16.89 16.55 16.77 16.32 16.66 16.09C16.54 15.74
				16.77 15.28 16.54 14.93C16.66 14.82 16.77 14.82 16.89 14.82C16.89 14.59 17 14.24 16.77 14.01C16.78
				14 16.88 13.91 16.89 13.9C16.77 13.55 16.66 13.09 17 12.86C17 12.75 17 12.63 16.89 12.63C17.23 12.17
				16.77 11.6 17.35 11.37C17.35 10.9 17.35 10.44 17.35 9.98C17.35 9.87 17.46 9.87 17.58 9.87C17.46 9.41
				17.69 9.06 17.58 8.6C17.69 8.49 17.69 8.26 17.92 8.14C17.94 8.15 18.03 8.25 18.04 8.26C17.92 7.68
				18.15 7.22 18.15 6.65C18.27 6.53 18.38 6.53 18.5 6.53C18.5 6.41 18.5 6.3 18.38 6.3C18.61 6.07 18.61
				5.84 18.61 5.49C18.84 5.26 18.96 4.92 19.07 4.57C19.07 4.46 19.07 4.34 18.96 4.34C19.19 4.34 18.96 4
				19.19 4C19.18 3.99 19.09 3.89 19.07 3.88C19.3 3.42 19.07 2.73 19.42 2.27C19.42 2.16 19.42 2.04 19.3
				2.04C19.53 1.69 19.3 1.23 19.3 0.89C19.33 0.89 19.51 0.89 19.53 0.89C19.77 1 19.53 1.23 19.77
				1.35C19.77 1.46 19.88 1.69 19.65 1.81C20 2.27 19.77 2.85 20.11 3.19C20.11 3.42 20 3.65 20.23
				3.88C20.21 3.89 20.12 3.99 20.11 4C20.23 4.11 20.23 4.11 20.34 4.23C20.33 4.24 20.24 4.33 20.23
				4.34C20.34 4.57 20.34 4.57 20.57 4.69C20.56 4.7 20.47 4.79 20.46 4.8C20.8 5.15 20.69 5.61 20.8
				5.95C20.92 5.95 21.03 5.95 21.03 6.07C21.03 6.18 21.03 6.3 21.15 6.41C21.13 6.43 21.04 6.52 21.03
				6.53C21.26 6.88 21.03 7.34 21.49 7.57C21.49 8.03 21.61 8.49 21.49 8.95C21.61 8.95 21.61 8.95 21.72
				8.95C21.95 9.52 21.72 10.1 21.84 10.67C21.95 10.67 22.07 10.67 22.07 10.79C22.07 11.13 22.18 11.48
				22.3 11.71C21.95 11.94 22.41 12.29 22.3 12.63C22.41 12.75 22.41 12.75 22.53 12.86C22.53 12.98 22.53
				13.09 22.41 13.21C22.64 13.67 22.3 14.24 22.41 14.7C22.53 14.7 22.64 14.7 22.64 14.82C22.64 15.05
				22.76 15.28 22.53 15.51C22.54 15.52 22.63 15.61 22.64 15.62C22.41 15.74 22.64 15.97 22.41
				16.09C22.41 17.01 22.64 17.93 22.18 18.73C22.3 18.85 22.3 18.85 22.41 18.96C22.41 19.54 22.3 20
				22.07 20.46C22.3 20.92 22.18 21.61 21.84 21.96C22.18 22.53 21.61 23.11 21.72 23.68C21.72 23.8 21.61
				23.8 21.49 23.8C21.61 23.91 21.61 23.91 21.72 24.03C21.72 24.14 21.72 24.14 21.72 24.26C21.72 24.37
				21.61 24.37 21.49 24.37C21.49 24.49 21.49 24.6 21.38 24.72C21.39 24.73 21.48 24.82 21.49 24.83C21.49
				24.95 21.38 24.95 21.26 24.95C21.26 25.3 21.03 25.53 21.26 25.87C21.26 25.99 21.15 25.99 21.03
				25.99C21.03 26.33 21.03 26.79 20.8 27.14C20.81 27.15 20.9 27.24 20.92 27.25C20.92 27.6 20.8 27.94
				20.69 28.17C20.7 28.19 20.79 28.28 20.8 28.29C20.46 28.63 20.92 28.98 20.57 29.33C20.57 29.44 20.57
				29.56 20.69 29.56C20.46 29.67 20.57 29.9 20.57 30.13C20.57 30.02 20.57 30.02 20.57 29.9C20.69 29.79
				20.69 29.79 20.8 29.67C20.92 28.98 21.15 28.29 21.61 27.83C21.49 27.48 21.95 27.14 21.61 26.91C21.84
				26.56 22.07 26.33 21.95 25.99C22.07 25.87 22.07 25.87 22.18 25.76C22.18 25.53 22.18 25.41 22.18
				25.18C22.3 25.07 22.41 25.07 22.53 25.07C22.53 24.83 22.53 24.6 22.53 24.37C22.64 24.26 22.64 24.03
				22.87 23.91C22.87 23.68 22.87 23.45 22.87 23.22C23.1 22.99 23.1 22.76 23.1 22.42C23.22 22.3 23.33
				22.3 23.45 22.3C23.44 22.29 23.35 22.2 23.33 22.19C23.56 21.96 23.45 21.61 23.68 21.38C23.67 21.37
				23.58 21.28 23.56 21.27C23.68 21.04 23.68 20.81 24.02 20.69C24.25 20.11 24.14 19.42 24.48 18.96C24.6
				18.96 24.6 18.96 24.72 18.96C24.72 18.73 24.83 18.73 24.95 18.62C24.95 18.16 25.06 17.7 25.29
				17.35C25.41 17.35 25.41 17.35 25.52 17.35C25.75 16.78 25.87 16.2 26.33 15.86C26.33 15.51 26.67 15.28
				26.67 14.93C27.13 14.59 27.59 14.13 27.59 13.55C27.71 13.32 27.94 13.44 28.05 13.44C28.05 13.21 28.4
				13.09 28.4 12.75C28.86 12.4 29.32 11.83 29.78 11.48C30.13 11.13 30.47 10.9 30.59 10.44C30.7 10.33
				30.93 10.33 31.05 10.1C31.16 10.1 31.16 10.1 31.28 10.1C31.74 9.52 32.31 9.18 32.89 8.72C33 8.49
				33.12 8.14 33.24 7.91C33.35 7.91 33.46 7.91 33.46 8.03C33.46 9.18 33 10.21 32.89 11.37C32.66 11.37
				32.89 11.71 32.66 11.71C32.66 11.83 32.66 11.94 32.66 12.06C32.54 12.17 32.54 12.17 32.43
				12.29C32.43 12.4 32.43 12.4 32.43 12.52C32.54 12.52 32.66 12.52 32.66 12.63C32.31 13.09 32.2 13.55
				31.97 14.01C32.08 14.13 32.08 14.24 32.08 14.36C31.85 14.36 32.08 14.7 31.85 14.7C31.85 15.16 31.62
				15.51 31.51 15.86C31.62 15.86 31.74 15.86 31.74 15.97C31.51 16.32 31.39 16.66 31.28 17.12C31.28
				17.24 31.16 17.24 31.05 17.24C31.05 17.35 31.05 17.47 31.16 17.47C31.05 17.81 30.93 18.27 30.59
				18.62C30.7 18.73 30.7 18.85 30.7 18.96C30.47 19.08 30.36 19.31 30.36 19.54C30.24 19.65 30.01 19.88
				29.9 20C30.01 20.46 29.44 20.81 29.32 21.27C29.32 21.38 29.2 21.38 29.09 21.38C28.86 21.73 28.86
				22.07 28.63 22.42C28.28 22.65 28.05 22.88 27.82 23.22C27.94 23.22 27.94 23.34 27.94 23.45C27.59
				23.91 26.9 24.14 26.9 24.72C26.56 24.83 26.33 25.18 25.98 25.3C26.1 25.64 25.75 25.87 25.52
				26.1C25.41 26.1 25.41 26.1 25.29 26.1C25.18 26.33 24.95 26.33 25.06 26.68C24.72 27.02 24.14 27.14
				24.02 27.71C22.99 28.52 22.07 29.56 21.15 30.59C21.26 30.59 21.26 30.59 21.38 30.59C21.49 30.48
				21.49 30.48 21.61 30.36C21.72 30.36 21.72 30.36 21.84 30.36C22.07 30.13 22.3 29.79 22.76 29.79C22.76
				29.67 22.76 29.56 22.87 29.56C22.99 29.56 23.1 29.56 23.22 29.56C23.45 29.33 23.79 29.1 24.02
				28.75C24.14 28.75 24.25 28.75 24.37 28.75C24.72 28.52 24.95 28.29 25.18 27.94C25.29 27.94 25.41
				27.94 25.52 27.94C25.87 27.6 26.21 27.14 26.79 27.25C26.9 27.14 26.9 27.14 27.02 27.02C27.13 27.02
				27.13 27.02 27.25 27.02C27.25 26.91 27.25 26.79 27.36 26.79C27.37 26.8 27.41 26.84 27.48 26.91C27.55
				26.84 27.59 26.8 27.59 26.79C27.71 26.79 27.82 26.79 27.82 26.91C28.05 26.91 28.17 26.68 28.4
				26.79C28.63 26.56 28.86 26.45 29.2 26.45C29.32 26.56 29.32 26.56 29.44 26.68C29.78 26.45 30.36 26.33
				30.82 26.56C30.83 26.55 30.92 26.46 30.93 26.45C31.05 26.45 31.16 26.45 31.16 26.56C31.28 26.33
				31.74 26.56 31.85 26.33C32.31 26.79 32.89 26.45 33.46 26.56C33.81 27.02 34.5 26.56 34.96 26.91C34.85
				26.91 34.85 27.02 34.85 27.14C35.31 27.37 35.65 27.25 36.11 27.25C36.23 27.37 36.23 27.48 36.23
				27.6C37.03 27.71 37.61 28.29 38.42 28.52C38.53 28.75 38.76 28.75 38.88 28.86C38.88 29.1 39.22 28.98
				39.11 29.21C39.11 29.33 38.99 29.33 38.88 29.33C38.76 29.21 38.76 29.21 38.65 29.1C38.63 29.11 38.54
				29.2 38.53 29.21C38.42 28.98 37.95 29.21 37.84 28.98C37.83 28.99 37.74 29.08 37.72 29.1C37.38 28.86
				37.03 28.98 36.69 28.86C36.57 28.98 36.46 29.1 36.34 29.21C36.33 29.2 36.24 29.11 36.23 29.1C35.65
				29.33 34.96 29.21 34.39 29.56C34.27 29.56 34.16 29.56 34.04 29.44C34.04 29.56 34.04 29.67 33.93
				29.79C33.91 29.77 33.82 29.68 33.81 29.67C33.46 29.9 33 29.9 32.66 29.9C32.54 30.13 32.2 30.02 31.97
				30.25C31.96 30.23 31.87 30.14 31.85 30.13C31.74 30.25 31.62 30.25 31.51 30.25C31.51 30.36 31.51
				30.48 31.39 30.48C31.16 30.48 30.93 30.59 30.7 30.36C30.69 30.37 30.66 30.41 30.59 30.48L30.47
				30.36L30.36 30.48C30.29 30.41 30.25 30.37 30.24 30.36C30.13 30.48 30.01 30.48 29.9 30.48C29.9 30.59
				29.9 30.71 29.78 30.71C28.86 30.59 28.05 31.05 27.13 30.71C27.02 30.71 26.9 30.71 26.9 30.82C26.89
				30.81 26.8 30.72 26.79 30.71C26.56 30.71 26.79 31.05 26.56 31.05C25.98 30.59 25.18 31.28 24.48
				31.05C24.14 31.51 23.56 31.05 23.1 31.4C22.99 31.4 22.87 31.4 22.87 31.28C22.3 31.28 21.61 31.28
				21.03 31.51C21.49 31.4 21.95 31.28 22.18 31.74C22.19 31.73 22.29 31.64 22.3 31.63C22.64 31.86 22.99
				31.51 23.22 31.97C23.56 32.09 23.91 32.09 24.25 32.09C24.37 32.55 24.72 32.66 25.18 32.55C25.29
				32.66 25.29 32.66 25.41 32.78C25.39 32.79 25.3 32.88 25.29 32.89C25.41 32.89 25.41 32.89 25.52
				32.89C25.64 33.01 25.64 33.01 25.75 33.12C25.76 33.11 25.86 33.02 25.87 33.01C25.98 33.12 26.21
				33.12 26.33 33.35C26.33 33.47 26.33 33.47 26.33 33.59C26.67 33.7 26.9 33.93 27.13 34.05C27.13 34.16
				27.13 34.28 27.13 34.39C27.25 34.62 27.59 34.62 27.71 34.85C27.59 35.43 28.4 35.54 28.63 36C28.62
				36.01 28.53 36.11 28.51 36.12C28.98 36.46 29.32 36.92 29.78 37.27C29.9 37.5 30.13 37.61 30.13
				37.84C30.24 37.84 30.36 37.84 30.36 37.96C30.36 38.07 30.36 38.07 30.36 38.19C30.36 38.31 30.24
				38.31 30.13 38.31C30.01 38.07 29.78 38.07 29.67 37.96C29.55 37.96 29.55 37.96 29.44 37.96C29.32
				37.84 29.32 37.84 29.2 37.73C28.74 37.61 28.28 37.5 27.94 37.15C27.71 37.15 27.48 37.15 27.36
				36.92C27.25 36.92 27.25 36.92 27.13 36.92C27.02 36.69 26.79 36.69 26.67 36.58C26.56 36.58 26.44
				36.58 26.44 36.69C26.1 36.46 25.75 36.35 25.41 36.12C25.41 36.23 25.41 36.35 25.29 36.35C25.18 36.12
				24.83 36.12 24.72 35.77C24.6 35.77 24.48 35.77 24.48 35.89C24.02 35.66 23.79 35.08 23.22 34.97C22.99
				34.62 22.76 34.16 22.3 34.16C22.07 33.93 21.95 33.59 21.72 33.35C21.61 33.35 21.61 33.35 21.49
				33.35C21.38 33.24 21.38 33.12 21.38 33.01C21.03 32.89 21.03 32.66 20.8 32.43C20.46 33.93 20.8 35.43
				20.69 36.92C20.92 37.27 20.92 37.61 20.92 37.96C20.8 38.31 20.57 38.65 20.23 38.88C20.11 38.88 20.11
				38.88 20 38.88C19.88 39 19.88 39 19.77 39.11C19.53 39 19.77 38.77 19.53 38.65C19.53 38.07 19.65
				37.38 19.53 36.81C19.88 36.58 19.53 36.23 19.77 36C19.77 34.85 19.77 33.59 19.77 32.43C19.65 32.43
				19.65 32.43 19.53 32.43C19.19 32.78 18.84 33.24 18.61 33.59C18.5 33.59 18.5 33.59 18.38 33.59C18.27
				33.93 18.04 34.28 17.81 34.51C17.69 34.51 17.69 34.51 17.58 34.51C17.69 34.74 17.23 34.62 17.46
				34.85C17.23 34.97 17 35.2 16.89 35.43C16.77 35.43 16.66 35.43 16.54 35.43C16.31 35.77 16.2 36.12
				15.85 36.23C15.74 36.23 15.62 36.23 15.62 36.12C15.51 36.35 15.28 36.46 15.16 36.69C14.7 36.46 14.35
				36.92 13.89 37.15C13.78 37.15 13.66 37.15 13.66 37.04C13.43 37.15 13.43 37.15 13.32 37.38C12.86 37.5
				12.4 37.73 11.94 38.07C11.82 38.07 11.71 38.07 11.59 38.07C11.59 38.19 11.59 38.31 11.48 38.31C11.02
				38.31 10.67 38.65 10.32 38.88C10.21 38.88 10.09 38.88 10.09 38.77C10.09 38.42 10.44 37.96 10.79
				37.84C10.79 37.73 10.79 37.73 10.79 37.61C11.13 37.27 11.71 36.92 11.82 36.35C12.05 36.23 12.17 36
				12.51 35.89C12.51 35.77 12.51 35.66 12.4 35.66C12.63 35.2 13.2 34.97 13.09 34.51C13.78 34.16 14.01
				33.47 14.7 33.24C14.93 33.24 14.81 32.89 15.05 32.89C15.16 32.89 15.39 32.89 15.51 32.89C15.74 32.78
				15.85 32.55 15.97 32.43C15.98 32.44 16.07 32.54 16.08 32.55C16.2 32.32 16.43 32.55 16.54 32.32C16.55
				32.33 16.65 32.42 16.66 32.43C16.89 32.32 16.89 32.32 17 32.09C17.35 31.97 17.69 31.97 18.04
				31.97C18.04 31.86 18.04 31.74 18.15 31.74C18.5 31.74 18.73 31.74 19.07 31.74C18.84 31.4 18.5 31.74
				18.27 31.51C18.26 31.52 18.17 31.62 18.15 31.63C18.04 31.63 17.81 31.74 17.69 31.51C17.58 31.51
				17.46 31.51 17.46 31.63C17.23 31.63 16.89 31.74 16.66 31.51C16.65 31.52 16.61 31.56 16.54
				31.63L16.43 31.51C16.36 31.58 16.32 31.62 16.31 31.63C16.2 31.63 16.08 31.63 15.97 31.51C15.97 31.63
				15.85 31.63 15.74 31.63C15.62 31.63 15.62 31.51 15.62 31.4C15.16 31.28 14.81 31.63 14.47 31.28C14.46
				31.29 14.37 31.39 14.35 31.4C14.24 31.4 14.12 31.4 14.12 31.28C14.11 31.29 14.02 31.39 14.01
				31.4C13.55 31.51 12.97 31.05 12.51 31.4C12.5 31.39 12.47 31.35 12.4 31.28C12.33 31.35 12.29 31.39
				12.28 31.4C11.94 31.51 11.71 31.28 11.48 31.17C11.13 31.4 10.67 31.17 10.21 31.28C10.09 31.17 10.09
				31.17 9.98 31.05C9.52 31.17 9.06 30.94 8.71 31.17C8.6 31.05 8.48 31.05 8.48 30.82C8.14 30.94 7.56
				30.94 7.33 30.59C7.22 30.59 7.1 30.59 7.1 30.71C6.99 30.71 6.87 30.71 6.87 30.59C6.86 30.6 6.83
				30.64 6.76 30.71C6.69 30.64 6.65 30.6 6.64 30.59C6.53 30.59 6.41 30.59 6.41 30.71C6.3 30.48 5.95
				30.59 5.95 30.25C5.84 30.25 5.72 30.25 5.72 30.36C5.6 30.36 5.49 30.36 5.37 30.25C5.26 30.25 5.14
				30.25 5.14 30.36C5.03 30.25 4.91 30.25 4.8 30.25C4.68 30.25 4.68 30.13 4.68 30.02C4.22 29.9 3.76
				30.36 3.53 29.9C2.84 29.79 2.27 30.02 1.58 30.02C1.46 30.25 1.23 30.36 1 30.13C1.12 29.9 1.46 29.79
				1.58 29.56C1.69 29.56 1.69 29.56 1.81 29.56C1.92 29.33 2.15 29.21 2.38 29.21C2.5 28.98 2.84 28.98
				2.96 28.75C3.07 28.75 3.07 28.75 3.19 28.75C3.3 28.63 3.3 28.63 3.42 28.52C3.43 28.53 3.52 28.62
				3.53 28.63C3.65 28.52 3.76 28.52 3.88 28.52C3.87 28.51 3.77 28.42 3.76 28.4C3.76 28.29 3.76 28.17
				3.88 28.17C3.99 28.17 4.22 28.29 4.34 28.06C4.35 28.07 4.44 28.16 4.45 28.17C4.8 28.06 5.03 27.94
				5.26 27.6C5.49 27.94 5.84 27.48 6.18 27.6C6.3 27.48 6.3 27.48 6.41 27.37C6.87 27.37 7.33 27.37 7.79
				27.37C7.79 27.25 7.79 27.14 7.91 27.02C8.25 27.25 8.71 27.25 9.17 27.25C9.29 27.14 9.29 27.14 9.4
				27.02C9.41 27.03 9.45 27.07 9.52 27.14C9.59 27.07 9.63 27.03 9.63 27.02C9.75 27.14 9.75 27.14 9.86
				27.25C9.88 27.24 9.97 27.15 9.98 27.14C10.09 27.37 10.32 27.25 10.55 27.25C10.55 27.14 10.55 27.02
				10.67 27.02C11.13 27.14 11.48 27.25 11.94 27.48C12.17 27.14 12.51 27.37 12.74 27.6C12.86 27.6 12.86
				27.6 12.97 27.6C13.09 27.83 13.32 27.83 13.55 27.71C13.78 27.94 14.24 28.06 14.35 28.4C14.93 28.29
				15.27 28.86 15.62 29.21C15.74 29.1 15.74 29.1 15.85 28.98C16.2 29.44 16.66 30.02 17.23 29.9C17.35
				30.02 17.35 30.02 17.46 30.13C17.58 30.13 17.58 30.13 17.69 30.13C18.04 30.48 18.5 30.59 18.96
				30.82C18.61 30.36 18.27 29.9 17.69 29.67C17.35 29.21 16.89 28.98 16.66 28.52C16.43 28.52 16.31 28.4
				16.2 28.29C16.31 28.06 15.97 28.17 15.85 27.94C15.85 27.83 15.85 27.83 15.85 27.71C15.39 27.48 15.04
				27.25 14.7 27.02C14.7 26.91 14.7 26.79 14.81 26.79C14.47 26.56 14.12 26.45 13.89 26.22C13.89 25.64
				13.2 25.53 12.86 25.18C12.86 25.07 12.86 25.07 12.86 24.95C12.51 24.6 12.05 24.37 11.82 24.03C11.59
				23.91 12.17 23.91 11.82 23.8C11.59 23.34 11.13 23.11 10.79 22.65C10.9 22.07 10.21 21.84 9.98
				21.5C9.98 21.38 9.98 21.38 9.98 21.27C9.86 21.27 9.75 21.27 9.75 21.15C9.86 20.81 9.63 20.69 9.4
				20.46C9.4 20.34 9.4 20.34 9.4 20.23C9.06 20.11 9.06 19.77 8.83 19.54C8.83 19.42 8.83 19.31 8.94
				19.31C8.71 18.96 8.48 18.62 8.25 18.27C8.37 18.16 8.37 18.04 8.37 17.93C8.02 17.58 7.91 17.12 7.68
				16.66C7.69 16.65 7.78 16.56 7.79 16.55C7.91 15.97 7.1 15.39 7.45 14.82C7.33 14.7 7.33 14.7 7.22
				14.59C7.22 14.47 7.22 14.47 7.22 14.36C7.1 14.36 6.99 14.36 6.99 14.24C7.1 13.9 6.76 13.67 6.64
				13.44C6.76 13.32 6.76 13.32 6.87 13.21C6.64 12.75 6.53 12.17 6.18 11.71C6.18 11.6 6.18 11.48 6.3
				11.37C6.07 10.9 5.95 10.33 5.72 9.87C5.73 9.86 5.82 9.76 5.84 9.75C5.6 9.64 5.84 9.41 5.6 9.29C5.62
				9.28 5.71 9.19 5.72 9.18C5.6 9.06 5.6 8.95 5.6 8.83C5.58 8.78 5.45 8.53 5.22 8.06L5.6 8.14L6.22
				8.76C6.65 9.08 6.89 9.27 6.94 9.3C7.08 9.3 7.08 9.3 7.21 9.3C7.35 9.58 7.62 9.58 7.76 9.71C7.9 10.26
				8.31 10.53 8.72 10.94C9.26 11.35 9.81 12.04 10.36 12.45C10.36 12.86 10.77 12.99 10.77 13.27"
						id={`${svgId}`}
					/>
				</defs>
				<use fill={color} xlinkHref={`#${svgId}`} />
				<use fillOpacity="0" stroke="#000" strokeOpacity="0" xlinkHref={`#${svgId}`} />
			</svg>
		</div>
	)
}

InventoryFlowerIcon.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	color: PropTypes.string,
}
InventoryFlowerIcon.defaultProps = {
	height: 40,
	width: 40,
	color: COLORS.GREEN,
}
export default InventoryFlowerIcon
