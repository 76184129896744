import React from 'react'
import propTypes from 'prop-types'
import { StyledStar } from 'components/FormStyles'

const Star = props => {
	const { fontSize } = props

	// has to return a function due to the API on StarRating
	//  (that I ripped out of  from https://github.com/voronianski/react-star-rating-component)
	// eslint-disable-next-line react/display-name
	return () => <StyledStar fontSize={fontSize}>★</StyledStar>
}

Star.propTypes = {
	fontSize: propTypes.string.isRequired,
}

export default Star
