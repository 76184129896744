import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FONT_SIZES } from 'utils/styleHelpers'

const CircleSVG = styled.svg`
	margin: 0 auto;
	display: block;
`

function CircleFull({ color, text, height = 40, width = 40 }) {
	return (
		<CircleSVG
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={`0 0 40 40`}
			width={width}
			height={height}
		>
			<defs>
				<path
					d="M22.6 0.17L23.86 0.37L25.1 0.66L26.31 1.02L27.48 1.45L28.62 1.95L29.72 2.52L30.78 3.16L31.8 3.86L32.77 4.62L33.69 5.43L34.56 6.3L35.37 7.22L36.13 8.19L36.82 9.2L37.46 10.26L38.03 11.36L38.53 12.5L38.97 13.68L39.33 14.89L39.61 16.13L39.82 17.39L39.94 18.68L39.99 20L39.94 21.31L39.82 22.6L39.61 23.87L39.33 25.11L38.97 26.32L38.53 27.49L38.03 28.63L37.46 29.73L36.82 30.79L36.13 31.81L35.37 32.78L34.56 33.7L33.69 34.56L32.77 35.38L31.8 36.14L30.78 36.83L29.72 37.47L28.62 38.04L27.48 38.54L26.31 38.98L25.1 39.34L23.86 39.62L22.6 39.83L21.31 39.95L19.99 40L18.68 39.95L17.39 39.83L16.12 39.62L14.88 39.34L13.68 38.98L12.5 38.54L11.36 38.04L10.26 37.47L9.2 36.83L8.19 36.14L7.22 35.38L6.3 34.56L5.43 33.7L4.61 32.78L3.86 31.81L3.16 30.79L2.52 29.73L1.95 28.63L1.45 27.49L1.02 26.32L0.66 25.11L0.37 23.87L0.17 22.6L0.04 21.31L0 20L0.04 18.68L0.17 17.39L0.37 16.13L0.66 14.89L1.02 13.68L1.45 12.5L1.95 11.36L2.52 10.26L3.16 9.2L3.86 8.19L4.61 7.22L5.43 6.3L6.3 5.43L7.22 4.62L8.19 3.86L9.2 3.16L10.26 2.52L11.36 1.95L12.5 1.45L13.68 1.02L14.88 0.66L16.12 0.37L17.39 0.17L18.68 0.04L19.99 0L21.31 0.04L22.6 0.17ZM17.3 5.24L16.01 5.53L14.76 5.94L13.56 6.44L12.42 7.05L11.34 7.74L10.33 8.53L9.39 9.39L8.52 10.34L7.74 11.35L7.04 12.43L6.44 13.57L5.93 14.77L5.53 16.01L5.23 17.3L5.05 18.63L4.99 20L5.05 21.36L5.23 22.69L5.53 23.98L5.93 25.23L6.44 26.43L7.04 27.57L7.74 28.65L8.52 29.66L9.39 30.6L10.33 31.47L11.34 32.25L12.42 32.95L13.56 33.55L14.76 34.06L16.01 34.46L17.3 34.76L18.63 34.94L19.99 35L21.36 34.94L22.69 34.76L23.98 34.46L25.22 34.06L26.42 33.55L27.56 32.95L28.64 32.25L29.65 31.47L30.6 30.6L31.46 29.66L32.25 28.65L32.94 27.57L33.55 26.43L34.05 25.23L34.46 23.98L34.75 22.69L34.93 21.36L34.99 20L34.93 18.63L34.75 17.3L34.46 16.01L34.05 14.77L33.55 13.57L32.94 12.43L32.25 11.35L31.46 10.34L30.6 9.39L29.65 8.53L28.64 7.74L27.56 7.05L26.42 6.44L25.22 5.94L23.98 5.53L22.69 5.24L21.36 5.06L19.99 5L18.63 5.06L17.3 5.24Z"
					id="g3Z55JqMVI"
				/>
			</defs>
			<use xlinkHref="#g3Z55JqMVI" fill={color || '#263238'} />
			{text && (
				<text
					style={{
						fill: color,
						fontSize: text.length > 1 ? FONT_SIZES.H4 : FONT_SIZES.H3,
						fontWeight: 'bold',
					}}
					y={text.length > 1 ? height + 1 : height + 3}
					x={text.length > 1 ? width - 17 : width - 13}
				>
					{text}
				</text>
			)}
		</CircleSVG>
	)
}

CircleFull.propTypes = {
	color: PropTypes.string,
	text: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
}
export default CircleFull
