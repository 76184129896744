import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { COLORS, FONT_SIZES } from 'utils/styleHelpers'

const CircleSVG = styled.svg`
	margin: 0 auto;
	display: block;
`
const EmptyPath = styled.path`
	fill: ${COLORS.MEDIUM_LIGHT_GRAY};
	transition: fill 0.5s;
	.dark & {
		fill: ${COLORS.MEDIUM_GRAY};
	}
`
function CircleLess({ color, text, height = 40, width = 40 }) {
	return (
		<CircleSVG
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={`0 0 40 40`}
			width={width}
			height={height}
		>
			<defs>
				<EmptyPath
					d="M22.61 0.17L23.88 0.38L25.11 0.66L26.32 1.02L27.5 1.45L28.64 1.96L29.74 2.53L30.8 3.16L31.81 3.86L32.78 4.62L33.7 5.43L34.57 6.3L35.38 7.22L36.14 8.19L36.84 9.21L37.47 10.27L38.04 11.37L38.55 12.51L38.98 13.68L39.34 14.89L39.62 16.13L39.83 17.39L39.96 18.69L40 20L39.96 21.31L39.83 22.61L39.62 23.87L39.34 25.11L38.98 26.32L38.55 27.49L38.04 28.63L37.47 29.73L36.84 30.79L36.14 31.81L35.38 32.78L34.57 33.7L33.7 34.57L32.78 35.38L31.81 36.14L30.8 36.84L29.74 37.47L28.64 38.04L27.5 38.55L26.32 38.98L25.11 39.34L23.88 39.62L22.61 39.83L21.32 39.96L20 40L18.69 39.96L17.4 39.83L16.13 39.62L14.9 39.34L13.69 38.98L12.51 38.55L11.37 38.04L10.27 37.47L9.21 36.84L8.2 36.14L7.23 35.38L6.31 34.57L5.44 33.7L4.63 32.78L3.87 31.81L3.17 30.79L2.54 29.73L1.97 28.63L1.46 27.49L1.03 26.32L0.67 25.11L0.39 23.87L0.18 22.61L0.05 21.31L0.01 20L5.01 20L5.07 21.36L5.25 22.69L5.54 23.99L5.94 25.23L6.45 26.43L7.05 27.57L7.75 28.65L8.53 29.66L9.4 30.6L10.34 31.47L11.36 32.25L12.44 32.95L13.58 33.55L14.77 34.06L16.02 34.46L17.31 34.76L18.64 34.94L20 35L21.37 34.94L22.7 34.76L23.99 34.46L25.24 34.06L26.43 33.55L27.57 32.95L28.65 32.25L29.67 31.47L30.61 30.6L31.48 29.66L32.26 28.65L32.96 27.57L33.56 26.43L34.07 25.23L34.47 23.99L34.76 22.69L34.94 21.36L35 20L34.94 18.64L34.76 17.31L34.47 16.01L34.07 14.77L33.56 13.57L32.96 12.43L32.26 11.35L31.48 10.34L30.61 9.4L29.67 8.53L28.65 7.75L27.57 7.05L26.43 6.45L25.24 5.94L23.99 5.54L22.7 5.24L21.37 5.06L20.01 5L20.01 0L21.32 0.04L22.61 0.17Z"
					id="a3ZDrrSHlo"
				/>
				<path
					d="M20 5L20 5L18.64 5.06L17.31 5.24L16.01 5.54L14.77 5.94L13.57 6.45L12.43 7.05L11.35 7.75L10.34 8.53L9.4 9.4L8.53 10.34L7.75 11.35L7.05 12.43L6.45 13.57L5.94 14.77L5.54 16.01L5.24 17.31L5.06 18.64L5 20L0 20L0.04 18.69L0.17 17.39L0.38 16.13L0.66 14.89L1.02 13.68L1.45 12.51L1.96 11.37L2.53 10.27L3.16 9.21L3.86 8.19L4.62 7.23L5.43 6.31L6.3 5.44L7.22 4.62L8.19 3.87L9.21 3.17L10.27 2.53L11.37 1.96L12.51 1.46L13.68 1.03L14.89 0.66L16.13 0.38L17.39 0.17L18.69 0.05L20 0L20 0.01L20 5Z"
					id="a3yDADKqF"
				/>
			</defs>
			<use xlinkHref="#a3ZDrrSHlo" />
			<use xlinkHref="#a3yDADKqF" fill={color} />
			{text && (
				<text
					style={{
						fill: color,
						fontSize: text.length > 1 ? FONT_SIZES.H4 : FONT_SIZES.H3,
						fontWeight: 'bold',
					}}
					y={text.length > 1 ? height + 1 : height + 3}
					x={text.length > 1 ? width - 17 : width - 13}
				>
					{text}
				</text>
			)}
		</CircleSVG>
	)
}

CircleLess.propTypes = {
	color: PropTypes.string,
	text: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
}

export default CircleLess
