import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from 'utils/styleHelpers'

const RotateRightIcon = ({ height, width, color }) => (
	<svg height={height} viewBox="0 0 40 40" width={width} xmlns="http://www.w3.org/2000/svg">
		<defs>
			<path
				d="M1.59 27.76C3.18 31.46 5.43 33.5 5.86 34.14C8.54 36.82 11.6 37.98 12.24 38.41C15.94 40 19.22 39.84 20 40C24.48 40 27.66 38.3 28.52 38.11C32.55 36.22 34.7 33.32 35.39 32.79C35.57 32.53 35.54 32.26 35.56 32.2C35.55 31.98 35.48 31.81 35.34 31.67C33.2 29.51 32.01 28.31 31.77 28.07C31.51 27.84 31.18 27.86 31.12 27.84C30.7 27.89 30.58 28.12 30.52 28.15C28.62 30.63 26.33 31.6 25.86 31.98C23.1 33.33 20.59 33.2 20 33.33C17.29 33.33 15.35 32.38 14.83 32.28C12.37 31.22 11 29.71 10.57 29.43C8.78 27.63 8.01 25.6 7.72 25.17C6.67 22.71 6.77 20.52 6.67 20C6.67 17.29 7.62 15.35 7.72 14.83C8.78 12.37 10.29 11 10.57 10.57C12.37 8.78 14.4 8.01 14.83 7.72C17.29 6.67 19.48 6.77 20 6.67C22.55 6.67 24.41 7.5 24.9 7.59C26.46 8.21 27.85 9.09 29.06 10.23C26.92 12.39 25.73 13.59 25.49 13.83C24.69 14.61 25.17 15.45 25.13 15.63C25.43 16.32 25.94 16.67 26.67 16.67C33.67 16.67 37.56 16.67 38.33 16.67C39.01 16.67 39.39 16.22 39.51 16.17C39.84 15.84 40 15.45 40 15C40 8 40 4.11 40 3.33C40 2.24 39.06 1.95 38.96 1.8C38.28 1.5 37.68 1.62 37.16 2.16C35.13 4.18 34 5.3 33.78 5.52C30.99 2.89 28.05 1.85 27.41 1.45C23.83 0 20.74 0.14 20 0C15.94 0 13.02 1.43 12.24 1.59C8.54 3.18 6.5 5.43 5.86 5.86C3.18 8.54 2.02 11.6 1.59 12.24C0.53 14.7 0 17.29 0 20C0.95 24.66 1.48 27.24 1.59 27.76Z"
				id="bqgM1gPgH"
			/>
		</defs>
		<use fill={COLORS.GREEN} xlinkHref="#bqgM1gPgH" />
	</svg>
)

RotateRightIcon.propTypes = {
	color: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
}
RotateRightIcon.defaultProps = {
	color: COLORS.GREEN,
	height: 40,
	width: 40,
}

export default RotateRightIcon
