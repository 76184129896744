import React, { useState, useEffect, useRef } from 'react'
import debounce from 'lodash.debounce'
import { Box } from '@rebass/emotion'
import { connectSearchBox } from 'react-instantsearch/connectors'

import PropTypes from 'prop-types'
import { ResetIcon } from 'components/Iconography'
import { NODE_TYPES } from 'utils/constants'

import { ResetButton, SearchBox, SearchFormWrapper, SearchFilterWrapper, SearchFilterBox } from './styles'
function ConnectedSearchBox({
	isFocusedOnLoad,
	refine,
	selectedFilter,
	setIsMoreResultsHidden,
	setParentSearchTerm,
	defaultSearchTerm,
	setSelectedFilter,
}) {
	const [searchTerm, setSearchTerm] = useState(null)
	const searchInput = useRef(null)

	const debouncedSearch = debounce(e => {
		const searchTerm = selectedFilter ? `${e.target.value} ${selectedFilter}` : e.target.value
		return refine(searchTerm)
	}, 400)
	useEffect(() => {
		if (isFocusedOnLoad) {
			searchInput.current.focus()
		}
	}, [])
	useEffect(() => {
		// const searchFilter = getUrlVars(window.location.href, 'f')
		if (searchTerm === null && defaultSearchTerm) {
			// const fullSearchTerm = searchFilter ? `${searchTermFromUrl} ${searchFilter}` : searchTermFromUrl
			setSearchTerm(defaultSearchTerm)
			refine(defaultSearchTerm)
			searchInput.current.value = defaultSearchTerm
			setParentSearchTerm(defaultSearchTerm)
		}
	}, [refine, setParentSearchTerm])

	const onChange = event => {
		// https://discourse.algolia.com/t/how-to-create-a-debounced-searchbox/1726/4
		event.persist()
		debouncedSearch(event, event.eventTarget)
		setSearchTerm(searchInput.current.value)
		setParentSearchTerm(searchInput.current.value)
		if (searchTerm !== null) {
			setIsMoreResultsHidden(false)
		}

		if (event.target.value === '') {
			setSelectedFilter(null)
			setIsMoreResultsHidden(true)
		}
	}

	const onReset = () => {
		searchInput.current.value = ''
		setSelectedFilter(null)
		refine('')
		setParentSearchTerm(null)
		searchInput.current.focus()
		setSearchTerm('')
		setIsMoreResultsHidden(true)
	}

	const setFilter = val => {
		const { CONCENTRATE, EDIBLE, FLOWER } = NODE_TYPES

		if (searchTerm) {
			if (val === CONCENTRATE || val === EDIBLE || val === FLOWER) {
				refine(`${searchTerm} ${val}`)
			} else {
				refine(`${searchTerm}`)
			}
		}
		setParentSearchTerm(searchTerm)
		setSelectedFilter(val)
	}
	return (
		<SearchFormWrapper flexDirection="column	">
			<Box flexGrow={0}>
				<form onReset={onReset}>
					<SearchBox
						aria-label="search terms"
						autoComplete="off"
						ref={searchInput}
						// https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md
						onChange={onChange}
						isResultsOpen={!!searchTerm}
						placeholder="How can we help you?"
						required
						type="text"
					/>
					<ResetButton type="reset">{ResetIcon}</ResetButton>
				</form>
			</Box>
			<Box>
				<SearchFilterWrapper flexDirection={['row', 'row']} flexWrap={['nowwrap', 'nowrap']}>
					<SearchFilterBox
						category={NODE_TYPES.FLOWER}
						index={NODE_TYPES.INVENTORY}
						isSelected={selectedFilter === NODE_TYPES.FLOWER}
						onClick={setFilter}
					/>
					<SearchFilterBox
						category={NODE_TYPES.CONCENTRATE}
						index={NODE_TYPES.INVENTORY}
						isSelected={selectedFilter === NODE_TYPES.CONCENTRATE}
						onClick={setFilter}
					/>
					<SearchFilterBox
						category={NODE_TYPES.EDIBLE}
						index={NODE_TYPES.INVENTORY}
						isSelected={selectedFilter === NODE_TYPES.EDIBLE}
						onClick={setFilter}
					/>
					<SearchFilterBox
						index={NODE_TYPES.DISPENSARIES}
						isSelected={selectedFilter === NODE_TYPES.DISPENSARIES}
						onClick={setFilter}
					/>
					<SearchFilterBox
						index={NODE_TYPES.STRAINS}
						isSelected={selectedFilter === NODE_TYPES.STRAINS}
						onClick={setFilter}
					/>
				</SearchFilterWrapper>
			</Box>
		</SearchFormWrapper>
	)
}
ConnectedSearchBox.propTypes = {
	isFocusedOnLoad: PropTypes.bool,
	defaultSearchTerm: PropTypes.string,
	refine: PropTypes.func.isRequired,
	selectedFilter: PropTypes.string,
	setIsMoreResultsHidden: PropTypes.func,
	setParentSearchTerm: PropTypes.func,
	setSelectedFilter: PropTypes.func,
}

ConnectedSearchBox.defaultProps = {
	defaultSearchTerm: '',
	isFocusedOnLoad: false,
	setIsMoreResultsHidden: () => null,
	setSelectedFilter: () => null,
}

export default connectSearchBox(props => {
	return <ConnectedSearchBox {...props} />
})
