import React from 'react'
import propTypes from 'prop-types'
import { COLORS } from 'utils/styleHelpers'

const ZoomInIcon = ({ width, height, color }) => (
	<svg height={height} viewBox="0 0 40 40" width={width}>
		<defs>
			<path d="M0 15L40 15L40 25L0 25L0 15Z" id="b2MAagaNzX" />
			<path d="M25 0L25 40L15 40L15 0L25 0Z" id="a1qtODEudz" />
		</defs>
		<use fill={color} xlinkHref="#b2MAagaNzX" />
		<use fillOpacity="0" stroke={color} strokeWidth="0" xlinkHref="#b2MAagaNzX" />
		<g>
			<use fill={color} xlinkHref="#a1qtODEudz" />
			<use fillOpacity="0" stroke={color} strokeOpacity="0" xlinkHref="#a1qtODEudz" />
		</g>
	</svg>
)

ZoomInIcon.propTypes = {
	color: propTypes.string,
	height: propTypes.number,
	width: propTypes.number,
}
ZoomInIcon.defaultProps = {
	color: COLORS.GREEN,
	height: 40,
	width: 40,
}

export default ZoomInIcon
