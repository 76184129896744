import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { COLORS } from 'utils/styleHelpers'
import { getLink } from 'utils/urlHelpers'
import SearchResultTracker from 'components/SearchResultTracker'
import { SelectTaxonomyIcon } from 'components/Iconography'
import { NODE_TYPES } from 'utils/constants'

import {
	DispensaryWrapper,
	ResultItem,
	ResultItemDetails,
	ResultLocationSearchWrapper,
	ResultItemTitle,
	ResultItemSubTitle,
	ResultLocation,
	ResultDistance,
	ResultItemIcon,
} from './styles'

function SearchResults({ clearSearchState, index, hits }) {
	return (
		<div numHits={hits.length || null}>
			{hits.map((hit, listIndex) => {
				try {
					const milesAway = hit._rankingInfo.geoDistance
						? (hit._rankingInfo.geoDistance * 0.000621371192).toFixed(2)
						: null
					const city = hit.city ? `${hit.city},` : ''
					const color =
						index.toLowerCase() === NODE_TYPES.INVENTORY || index.toLowerCase() === NODE_TYPES.DISPENSARIES
							? COLORS.GREEN
							: COLORS.BLACK
					const to = `${getLink(hit, index.toLowerCase())}`
					return (
						<SearchResultTracker
							key={`href_${hit.objectID}`}
							clearSearchState={clearSearchState}
							inventoryCategory={hit.category ? hit.category : index}
							to={to}
							nodeCity={hit.city}
							nodeID={hit.objectID}
							nodeLabel={hit.name}
							nodeState={hit.state}
							nodeType={index}
							nodeZip={hit.zip}
							productId={hit.objectID.toString()}
						>
							<ResultItem key={hit.objectID} index={index} listIndex={listIndex}>
								<ResultItemIcon>
									<SelectTaxonomyIcon category={hit.category} color={color} index={index} />
								</ResultItemIcon>
								<ResultLocationSearchWrapper>
									<ResultItemTitle>{hit.name}</ResultItemTitle>
									<ResultItemDetails>
										<DispensaryWrapper>
											<ResultItemSubTitle>
												{hit.dispensaryName ? (
													<div
														css={css`
																font-weight: bold;
																color: ${COLORS.GRAY};
																transition: color 0.5s;
																.dark & {
																	color: ${COLORS.MEDIUM_LIGHT_GRAY};
																}
															`}
													>
														{hit.dispensaryName}
													</div>
												) : (
														hit.address1
													)}
											</ResultItemSubTitle>

											{hit.addresss2 && (
												<ResultItemSubTitle>{hit.address2}</ResultItemSubTitle>
											)}
											<ResultLocation index={index}>
												{city} {hit.state} {hit.zip}
											</ResultLocation>
											{index !== NODE_TYPES.STRAINS && (
												<ResultDistance>{milesAway} miles away</ResultDistance>
											)}
										</DispensaryWrapper>
									</ResultItemDetails>
								</ResultLocationSearchWrapper>
							</ResultItem>
						</SearchResultTracker>
					)
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(`${error.message} for ${hit.name}`)
					return <div />
				}
			})}
		</div>
	)
}

SearchResults.propTypes = {
	clearSearchState: PropTypes.func,
	hits: PropTypes.array,
	index: PropTypes.string
}
export default SearchResults
