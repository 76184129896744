import React from 'react'
import PropTypes from 'prop-types'
import { COLORS, SPACERS, FONT_SIZES } from 'utils/styleHelpers'
import { genSVGId } from 'utils/genGuid'

const DispensaryLocationIcon = ({ width, height, color, innerColor, text = null }) => {
	const svgId = genSVGId()
	const svgIdDot = genSVGId()
	return (
		<div>
			<svg height={height} viewBox="0 0 40 40" width={width}>
				<defs>
					<path
						d="M15.81 28.7C14.42 26.24 12.81 23.96 11.32 21.57C10.82 20.78 10.39 19.93 9.92 19.1C8.96 17.45 8.19
					15.53 8.23 13.04C8.28 10.6 8.99 8.65 10 7.05C11.67 4.43 14.47 2.28 18.22 1.71C21.29 1.25 24.16 2.03
					26.2 3.22C27.87 4.2 29.16 5.5 30.14 7.03C31.16 8.63 31.87 10.53 31.93 12.99C31.96 14.26 31.75 15.43
					31.46 16.4C31.16 17.38 30.69 18.2 30.27 19.08C29.44 20.8 28.41 22.37 27.37 23.94C24.28 28.61 21.38
					33.39 20.11 39.92C19.24 35.61 17.69 32.03 15.81 28.7Z"
						id={`${svgIdDot}`}
					/>
					<path
						d="M20.17 8.16C17.88 8.16 16.02 10.02 16.02 12.31C16.02 14.6 17.88 16.46 20.17 16.46C22.47 16.46 24.33
					14.6 24.33 12.31C24.33 10.02 22.47 8.16 20.17 8.16Z"
						id={`${svgId}`}
					/>
				</defs>

				<use fill={color} xlinkHref={`#${svgIdDot}`} />
				<use fillOpacity="0" stroke={color} strokeWidth="0" xlinkHref={`#${svgIdDot}`} />
				{!text ? (
					<g>
						<use fill={innerColor} xlinkHref={`#${svgId}`} />
						<use fillOpacity="0" stroke={color} strokeOpacity="0" xlinkHref={`#${svgId}`} />
					</g>
				) : (
					<text
						style={{
							fill: COLORS.WHITE,
							fontWeight: 'bold',
							fontSize: text.length > 1 ? FONT_SIZES.H7 : FONT_SIZES.H4,
							paddingBottom: SPACERS.M,
						}}
						dominantBaseline="middle"
						textAnchor="middle"
						y={`${height / 2 - 5}`}
						x={`${width / 2}`}
					>
						{text}
					</text>
				)}
			</svg>
		</div>
	)
}

DispensaryLocationIcon.propTypes = {
	color: PropTypes.string,
	height: PropTypes.number,
	innerColor: PropTypes.string,
	width: PropTypes.number,
	text: PropTypes.string,
}
DispensaryLocationIcon.defaultProps = {
	color: COLORS.GREEN,
	height: 40,
	innerColor: COLORS.WHITE,
	width: 40,
}

export default DispensaryLocationIcon
