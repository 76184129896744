import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import track, { TrackingPropType } from 'react-tracking'
import { COLORS } from 'utils/styleHelpers'
import { stampTime, eventDispatch } from 'utils/tracker'

const NakedLink = styled(Link)`
	color: ${COLORS.GREEN};
	text-decortion: none;
	&:hover {
		text-decoration: none; !imporant
	}
`

const LinkWrapper = ({ to, children, state, tracker, tracking }) => {
	const handleTracking = tracker ? () => tracking.trackEvent({ ...tracker }) : () => true
	if (typeof to !== 'undefined' && to !== '') {
		return (
			<NakedLink state={{ ...state }} onClick={handleTracking} to={to}>
				{children}
			</NakedLink>
		)
	}
	return children
}

LinkWrapper.propTypes = {
	to: PropTypes.string,
	tracker: PropTypes.object,
	tracking: TrackingPropType,
}

LinkWrapper.defaultProps = {
	to: null,
	tracker: null,
}

export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(LinkWrapper)
