import React, { useEffect, useState, useContext } from 'react'
import { CookieContext } from 'components/AuthContext'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import { Box, Flex } from '@rebass/emotion'
import styled from '@emotion/styled'
import moment from 'moment'
import { COLORS, SPACERS, MAX_WIDTH, MQ } from 'utils/styleHelpers'
import { FormBox } from 'components/FormLayout'
import { ErrorMessage, StyledButton } from 'components/FormStyles'

const StyledOverlay = styled.div`
	background: ${COLORS.WHITE};
	border-radius: 4px;
	box-shadow: 0 0 0 60vmax rgba(0, 0, 0, 0.25), 0 0 32px rgba(0, 0, 0, 0.25);
	left: 50%;
	max-width: 100vw;
	max-height: 100vh;
	overflow: auto;
	padding: 16px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: ${MAX_WIDTH.M};
	height: calc(80vh - ${SPACERS.HEADER});
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
	${MQ.L} {
		width: ${MAX_WIDTH.L};
		height: calc(70vh - ${SPACERS.HEADER});
	}
`
const OverlayTitle = styled('h2')`
	text-align: center;
	text-transform: uppercase;
`
const Blocker = styled.div`
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	z-index: 4;
`
function VerifyAgeModal({ hasAgeVerified }) {
	const [hasVerified, setHasVerified] = useState(false)
	const [showOverlay, setShowOverlay] = useState(false)
	const [error, setError] = useState(null)
	const { addCookie, ageVerified } = useContext(CookieContext)

	useEffect(() => {
		setShowOverlay(!hasAgeVerified)
	}, [hasAgeVerified])

	const onVerifyCheck = event => {
		setHasVerified(event.target.checked)
		setError(null)
	}

	const onCancelClick = event => {
		event.stopPropagation()
	}

	const onVerifySubmit = () => {
		if (!hasVerified) {
			setError(`Please check the checkbox verifying you agree to the terms.`)
			return
		}
		addCookie('ageVerified', hasVerified, {
			path: '/',
			expires: new Date(
				moment()
					.add(5, 'y')
					.format()
			),
		})
		setHasVerified(hasVerified)
		return
	}

	if (!showOverlay || ageVerified) {
		return null
	}

	return (
		<Blocker>
			<StyledOverlay onClick={onCancelClick}>
				<Flex alignItems="center" flexDirection="column" justifyContent="center">
					<Box>
						<OverlayTitle>Welcome!</OverlayTitle>
					</Box>
					<Box>
						<Flex flexDirection="column" justifyContent="center">
							<Box alignSelf="center" px={[2, 4]} py={3} width={[1, 1]}>
								<input onChange={onVerifyCheck} type="checkbox" /> I am at least 21 years old and I
								agree to the&nbsp;
								<a href="/privacy-policy" target="_blank">
									Privacy Policy
								</a>
								&nbsp;and{' '}
								<a href="/terms-of-use" target="_blank">
									Terms of Use.
								</a>
							</Box>
							<Box alignSelf="center" py={2} width={[2 / 3, 1 / 2]}>
								<Flex justifyContent="center">
									<Box alignSelf="center">
										<StyledButton bg={COLORS.BLACK} onClick={onVerifySubmit}>
											Continue
										</StyledButton>
									</Box>
								</Flex>
							</Box>
							<Box alignSelf="center" px={[2, 4]} py={3} width={[1]}>
								We also use cookies to help us deliver a personalized experience and display relevant
								promotions. By using this website, you agree to our use of cookies. Please take a moment
								to review our{' '}
								<a href="/cookie-policy" target="_blank">
									cookie policy
								</a>
							</Box>
						</Flex>
					</Box>
					<FormBox>
						<ErrorMessage>{error}</ErrorMessage>
					</FormBox>
				</Flex>
			</StyledOverlay>
		</Blocker>
	)
}

VerifyAgeModal.propTypes = {
	hasAgeVerified: propTypes.bool,
}
export default VerifyAgeModal
