import React from 'react'
import styled from '@emotion/styled'
import propTypes from 'prop-types'
import { COLORS } from 'utils/styleHelpers'
import { genSVGId } from 'utils/genGuid'

const StrainIconWrapper = styled.div`
	svg {
		fill: ${({ color }) => color};
		transition: fill 0.5s;
	}
	.dark & {
		svg {
			fill: ${COLORS.WHITE};
		}
	}
`

const StrainIcon = ({ width, height, color }) => {
	const svgId1 = genSVGId()
	const svgId2 = genSVGId()
	const svgId3 = genSVGId()
	return (
		<StrainIconWrapper color={color}>
			<svg height={height} viewBox="0 0 40 40" width={width}>
				<defs>
					<path
						d="M7.79 2.53L8.7 2.6L9.58 2.69L10.45 2.81L11.28 2.94L12.1 3.09L12.88 3.26L13.64 3.44L14.37
				3.63L15.07 3.82L15.74 4.03L16.38 4.23L16.98 4.44L17.54 4.65L18.07 4.85L18.56 5.05L19.01 5.24L19.42
				5.42L19.79 5.59L20.12 5.74L20.4 5.88L20.63 6L20.81 6.1L20.95 6.17L21.04 6.22L21.13 6.17L21.27
				6.1L21.45 6L21.69 5.88L21.96 5.74L22.29 5.59L22.66 5.42L23.07 5.24L23.52 5.05L24.01 4.85L24.54
				4.65L25.1 4.44L25.7 4.23L26.34 4.03L27.01 3.82L27.71 3.63L28.44 3.44L29.2 3.26L29.98 3.09L30.8
				2.94L31.64 2.81L32.5 2.69L33.38 2.6L34.29 2.53L35.22 2.49L36.16 2.47L37 2.47L37 35.77L36.16
				35.77L35.22 35.78L34.3 35.83L33.4 35.9L32.52 36L31.66 36.13L30.82 36.27L30 36.43L29.21 36.6L28.45
				36.79L27.72 36.99L27.02 37.2L26.35 37.41L25.72 37.62L25.13 37.83L24.57 38.04L24.05 38.25L23.57
				38.45L23.14 38.64L22.75 38.81L22.4 38.97L22.11 39.11L21.86 39.24L21.67 39.33L21.53 39.41L21.44
				39.46L21.42 39.47L21.04 39.68L20.66 39.47L20.64 39.46L20.55 39.41L20.41 39.33L20.22 39.24L19.97
				39.11L19.68 38.97L19.33 38.81L18.94 38.64L18.51 38.45L18.03 38.25L17.51 38.04L16.95 37.83L16.36
				37.62L15.73 37.41L15.06 37.2L14.36 36.99L13.63 36.79L12.87 36.6L12.08 36.43L11.26 36.27L10.42
				36.13L9.56 36L8.68 35.9L7.78 35.83L6.86 35.78L5.92 35.77L5.08 35.77L5.08 2.47L5.92 2.47L6.87
				2.49L7.79 2.53ZM32.94 4.63L31.81 4.78L30.72 4.96L29.68 5.16L28.68 5.39L27.74 5.64L26.85
				5.89L26.02 6.16L25.25 6.42L24.55 6.68L23.91 6.93L23.35 7.17L22.86 7.38L22.45 7.57L22.12 7.73L21.88
				7.85L21.88 8.16L20.2 8.16L20.2 7.85L20.03 7.78L19.78 7.69L19.45 7.58L19.05 7.46L18.57 7.32L18.01
				7.17L17.37 7L16.66 6.81L15.87 6.61L15 6.4L14.06 6.17L13.03 5.92L11.93 5.66L10.76 5.39L9.5 5.09L8.17
				4.78L6.76 4.46L6.76 33.95L7.91 34L9.03 34.1L10.11 34.22L11.15 34.38L12.15 34.56L13.11 34.76L14.02
				34.97L14.88 35.2L15.7 35.44L16.46 35.69L17.17 35.93L17.82 36.17L18.42 36.41L18.78 36.56L18.78
				36.65L18.99 36.65L19.44 36.84L19.85 37.03L20.2 37.2L20.2 36.65L21.88 36.65L21.88 37.2L22.23
				37.03L22.64 36.84L23.12 36.63L23.66 36.41L24.26 36.17L24.91 35.93L25.62 35.69L26.38 35.44L27.2
				35.2L28.06 34.97L28.97 34.76L29.93 34.56L30.93 34.38L31.97 34.22L33.05 34.1L34.17 34L35.32 33.95L35.32
				4.46L34.11 4.52L32.94 4.63Z"
						id={svgId1}
					/>
					<path
						d="M15.23 16.71C15.45 16.85 15.52 17.07 15.66 17.28C15.74 17.28 15.74 17.28 15.81 17.28C15.88 17.36
				15.96 17.43 16.03 17.5C16.03 17.57 16.03 17.57 16.03 17.64C16.1 17.64 16.18 17.64 16.18 17.71C16.18
				17.79 16.18 17.86 16.18 17.93C16.32 18.07 16.47 18 16.54 18.14C16.54 18.22 16.54 18.22 16.54
				18.29C16.61 18.29 16.69 18.29 16.69 18.36C16.61 18.5 16.83 18.57 16.76 18.72C17.12 18.93 17.2 19.29
				17.34 19.65C17.42 19.65 17.42 19.65 17.49 19.65C17.56 19.72 17.56 19.72 17.63 19.79C17.63 20.15
				17.85 20.51 18.14 20.72C18.14 20.8 18.14 20.8 18.14 20.87C18.22 20.94 18.22 20.94 18.29 21.01C18.29
				21.22 18.29 21.37 18.44 21.51C18.44 21.58 18.44 21.65 18.44 21.73C18.58 21.73 18.58 21.8 18.66
				21.87C18.73 22.16 18.73 22.58 19.02 22.73C19.02 22.8 19.02 22.87 19.02 22.94C19.24 23.09 19.09 23.3
				19.24 23.52C19.23 23.52 19.17 23.58 19.17 23.59C19.24 23.66 19.31 23.73 19.38 23.8C19.38 23.95 19.46
				24.09 19.53 24.23C19.52 24.24 19.46 24.3 19.46 24.3C19.46 24.45 19.68 24.3 19.68 24.45C19.75 24.73
				19.82 24.95 19.89 25.24C19.97 25.24 20.04 25.24 20.04 25.31C20.04 25.45 20.04 25.59 20.04
				25.74C20.19 25.81 20.19 25.95 20.19 26.1C20.55 26.53 20.62 27.03 20.92 27.46C20.99 27.24 20.84 26.96
				20.84 26.74C20.7 26.6 20.77 26.38 20.62 26.24C20.63 26.23 20.69 26.17 20.7 26.17C20.48 25.95 20.55
				25.52 20.48 25.24C20.41 25.24 20.33 25.24 20.33 25.16C20.48 24.81 20.19 24.38 20.04 24.02C20.04
				23.95 20.04 23.88 20.11 23.88C20.11 23.8 20.11 23.73 20.04 23.73C20.05 23.72 20.11 23.67 20.11
				23.66C20.04 23.59 20.04 23.59 19.97 23.52C19.97 23.3 19.89 23.16 19.75 23.01C19.75 22.87 19.89 22.8
				19.82 22.66C19.75 22.58 19.75 22.58 19.68 22.51C19.6 22.3 19.68 22.01 19.46 21.8C19.46 21.73 19.46
				21.65 19.53 21.58C19.38 21.22 19.31 20.8 19.24 20.44C19.38 20.22 19.24 19.86 19.09 19.65C19.17 19.43
				18.87 19.15 19.24 19.08C19.24 18.86 19.17 18.72 19.09 18.57C19.02 18.36 19.17 18.07 19.02
				17.86C19.09 17.79 19.17 17.79 19.24 17.79C19.24 17.64 19.31 17.43 19.17 17.28C19.17 17.28 19.23
				17.22 19.24 17.21C19.17 17 19.09 16.71 19.31 16.57C19.31 16.5 19.31 16.43 19.24 16.43C19.46 16.14
				19.17 15.78 19.53 15.64C19.53 15.35 19.53 15.06 19.53 14.78C19.53 14.71 19.6 14.71 19.68 14.71C19.6
				14.42 19.75 14.2 19.68 13.92C19.75 13.85 19.75 13.7 19.89 13.63C19.9 13.64 19.96 13.7 19.97
				13.7C19.89 13.34 20.04 13.06 20.04 12.7C20.11 12.63 20.19 12.63 20.26 12.63C20.26 12.56 20.26 12.48
				20.19 12.48C20.33 12.34 20.33 12.2 20.33 11.98C20.48 11.84 20.55 11.63 20.62 11.41C20.62 11.34 20.62
				11.27 20.55 11.27C20.7 11.27 20.55 11.05 20.7 11.05C20.69 11.05 20.63 10.99 20.62 10.98C20.77 10.69
				20.62 10.26 20.84 9.98C20.84 9.91 20.84 9.83 20.77 9.83C20.92 9.62 20.77 9.33 20.77 9.12C20.78 9.12
				20.9 9.12 20.92 9.12C21.06 9.19 20.92 9.33 21.06 9.4C21.06 9.48 21.13 9.62 20.99 9.69C21.21 9.98
				21.06 10.34 21.28 10.55C21.28 10.69 21.21 10.84 21.35 10.98C21.35 10.99 21.29 11.05 21.28
				11.05C21.35 11.12 21.35 11.12 21.43 11.2C21.42 11.2 21.36 11.26 21.35 11.27C21.43 11.41 21.43 11.41
				21.57 11.48C21.56 11.49 21.51 11.55 21.5 11.55C21.72 11.77 21.64 12.05 21.72 12.27C21.79 12.27 21.86
				12.27 21.86 12.34C21.86 12.41 21.86 12.48 21.94 12.56C21.93 12.56 21.87 12.62 21.86 12.63C22.01
				12.84 21.86 13.13 22.16 13.27C22.16 13.56 22.23 13.85 22.16 14.13C22.23 14.13 22.23 14.13 22.3
				14.13C22.45 14.49 22.3 14.85 22.37 15.21C22.45 15.21 22.52 15.21 22.52 15.28C22.52 15.49 22.59 15.71
				22.67 15.85C22.45 16 22.74 16.21 22.67 16.43C22.74 16.5 22.74 16.5 22.81 16.57C22.81 16.64 22.81
				16.71 22.74 16.78C22.88 17.07 22.67 17.43 22.74 17.71C22.81 17.71 22.88 17.71 22.88 17.79C22.88
				17.93 22.96 18.07 22.81 18.22C22.82 18.22 22.88 18.28 22.88 18.29C22.74 18.36 22.88 18.5 22.74
				18.57C22.74 19.15 22.88 19.72 22.59 20.22C22.67 20.29 22.67 20.29 22.74 20.36C22.74 20.72 22.67
				21.01 22.52 21.3C22.67 21.58 22.59 22.01 22.37 22.23C22.59 22.58 22.23 22.94 22.3 23.3C22.3 23.37
				22.23 23.37 22.16 23.37C22.23 23.45 22.23 23.45 22.3 23.52C22.3 23.59 22.3 23.59 22.3 23.66C22.3
				23.73 22.23 23.73 22.16 23.73C22.16 23.8 22.16 23.88 22.08 23.95C22.09 23.95 22.15 24.01 22.16
				24.02C22.16 24.09 22.08 24.09 22.01 24.09C22.01 24.3 21.86 24.45 22.01 24.66C22.01 24.73 21.94 24.73
				21.86 24.73C21.86 24.95 21.86 25.24 21.72 25.45C21.72 25.46 21.78 25.52 21.79 25.52C21.79 25.74
				21.72 25.95 21.64 26.1C21.65 26.1 21.71 26.16 21.72 26.17C21.5 26.38 21.79 26.6 21.57 26.81C21.57
				26.88 21.57 26.96 21.64 26.96C21.5 27.03 21.57 27.17 21.57 27.31C21.57 27.24 21.57 27.24 21.57
				27.17C21.64 27.1 21.64 27.1 21.72 27.03C21.79 26.6 21.94 26.17 22.23 25.88C22.16 25.67 22.45 25.45
				22.23 25.31C22.37 25.09 22.52 24.95 22.45 24.73C22.52 24.66 22.52 24.66 22.59 24.59C22.59 24.45
				22.59 24.38 22.59 24.23C22.67 24.16 22.74 24.16 22.81 24.16C22.81 24.02 22.81 23.88 22.81
				23.73C22.88 23.66 22.88 23.52 23.03 23.45C23.03 23.3 23.03 23.16 23.03 23.01C23.18 22.87 23.18 22.73
				23.18 22.51C23.25 22.44 23.32 22.44 23.4 22.44C23.39 22.43 23.33 22.38 23.32 22.37C23.47 22.23 23.4
				22.01 23.54 21.87C23.53 21.86 23.48 21.8 23.47 21.8C23.54 21.65 23.54 21.51 23.76 21.44C23.91 21.08
				23.83 20.65 24.05 20.36C24.12 20.36 24.12 20.36 24.2 20.36C24.2 20.22 24.27 20.22 24.34 20.15C24.34
				19.86 24.42 19.58 24.56 19.36C24.63 19.36 24.63 19.36 24.71 19.36C24.85 19 24.93 18.65 25.22
				18.43C25.22 18.22 25.44 18.07 25.44 17.86C25.73 17.64 26.02 17.36 26.02 17C26.09 16.85 26.24 16.93
				26.31 16.93C26.31 16.78 26.53 16.71 26.53 16.5C26.82 16.28 27.11 15.92 27.41 15.71C27.62 15.49 27.84
				15.35 27.92 15.06C27.99 14.99 28.14 14.99 28.21 14.85C28.28 14.85 28.28 14.85 28.35 14.85C28.65
				14.49 29.01 14.28 29.38 13.99C29.45 13.85 29.52 13.63 29.59 13.49C29.67 13.49 29.74 13.49 29.74
				13.56C29.74 14.28 29.45 14.92 29.38 15.64C29.23 15.64 29.38 15.85 29.23 15.85C29.23 15.92 29.23 16
				29.23 16.07C29.16 16.14 29.16 16.14 29.08 16.21C29.08 16.28 29.08 16.28 29.08 16.35C29.16 16.35
				29.23 16.35 29.23 16.43C29.01 16.71 28.94 17 28.79 17.28C28.86 17.36 28.86 17.43 28.86 17.5C28.72
				17.5 28.86 17.71 28.72 17.71C28.72 18 28.57 18.22 28.5 18.43C28.57 18.43 28.65 18.43 28.65 18.5C28.5
				18.72 28.43 18.93 28.35 19.22C28.35 19.29 28.28 19.29 28.21 19.29C28.21 19.36 28.21 19.43 28.28
				19.43C28.21 19.65 28.14 19.93 27.92 20.15C27.99 20.22 27.99 20.29 27.99 20.36C27.84 20.44 27.77
				20.58 27.77 20.72C27.7 20.79 27.55 20.94 27.48 21.01C27.55 21.3 27.19 21.51 27.11 21.8C27.11 21.87
				27.04 21.87 26.97 21.87C26.82 22.08 26.82 22.3 26.68 22.51C26.46 22.66 26.31 22.8 26.17 23.01C26.24
				23.01 26.24 23.09 26.24 23.16C26.02 23.45 25.58 23.59 25.58 23.95C25.36 24.02 25.22 24.23 25
				24.3C25.07 24.52 24.85 24.66 24.71 24.81C24.63 24.81 24.63 24.81 24.56 24.81C24.49 24.95 24.34 24.95
				24.42 25.16C24.2 25.38 23.83 25.45 23.76 25.81C23.1 26.31 22.52 26.96 21.94 27.6C22.01 27.6 22.01
				27.6 22.08 27.6C22.16 27.53 22.16 27.53 22.23 27.46C22.3 27.46 22.3 27.46 22.37 27.46C22.52 27.31
				22.67 27.1 22.96 27.1C22.96 27.03 22.96 26.96 23.03 26.96C23.1 26.96 23.18 26.96 23.25 26.96C23.4
				26.81 23.61 26.67 23.76 26.45C23.83 26.45 23.91 26.45 23.98 26.45C24.2 26.31 24.34 26.17 24.49
				25.95C24.56 25.95 24.63 25.95 24.71 25.95C24.93 25.74 25.15 25.45 25.51 25.52C25.58 25.45 25.58
				25.45 25.66 25.38C25.73 25.38 25.73 25.38 25.8 25.38C25.8 25.31 25.8 25.24 25.87 25.24C25.88 25.24
				25.9 25.26 25.95 25.31C25.99 25.26 26.02 25.24 26.02 25.24C26.09 25.24 26.17 25.24 26.17 25.31C26.31
				25.31 26.39 25.16 26.53 25.24C26.68 25.09 26.82 25.02 27.04 25.02C27.11 25.09 27.11 25.09 27.19
				25.16C27.41 25.02 27.77 24.95 28.06 25.09C28.07 25.09 28.13 25.03 28.14 25.02C28.21 25.02 28.28
				25.02 28.28 25.09C28.35 24.95 28.65 25.09 28.72 24.95C29.01 25.24 29.38 25.02 29.74 25.09C29.96
				25.38 30.4 25.09 30.69 25.31C30.61 25.31 30.61 25.38 30.61 25.45C30.91 25.59 31.13 25.52 31.42
				25.52C31.49 25.59 31.49 25.67 31.49 25.74C32 25.81 32.37 26.17 32.88 26.31C32.95 26.45 33.1 26.45
				33.17 26.53C33.17 26.67 33.39 26.6 33.31 26.74C33.31 26.81 33.24 26.81 33.17 26.81C33.1 26.74 33.1
				26.74 33.02 26.67C33.01 26.68 32.96 26.73 32.95 26.74C32.88 26.6 32.58 26.74 32.51 26.6C32.5 26.6
				32.45 26.66 32.44 26.67C32.22 26.53 32 26.6 31.78 26.53C31.71 26.6 31.64 26.67 31.56 26.74C31.56
				26.73 31.5 26.68 31.49 26.67C31.13 26.81 30.69 26.74 30.32 26.96C30.25 26.96 30.18 26.96 30.11
				26.88C30.11 26.96 30.11 27.03 30.03 27.1C30.02 27.09 29.97 27.03 29.96 27.03C29.74 27.17 29.45 27.17
				29.23 27.17C29.16 27.31 28.94 27.24 28.79 27.38C28.79 27.38 28.73 27.32 28.72 27.31C28.65 27.38
				28.57 27.38 28.5 27.38C28.5 27.46 28.5 27.53 28.43 27.53C28.28 27.53 28.14 27.6 27.99 27.46C27.98
				27.46 27.96 27.49 27.92 27.53C27.91 27.52 27.89 27.5 27.84 27.46C27.8 27.5 27.78 27.52 27.77
				27.53C27.73 27.49 27.7 27.46 27.7 27.46C27.62 27.53 27.55 27.53 27.48 27.53C27.48 27.6 27.48 27.67
				27.41 27.67C26.82 27.6 26.31 27.89 25.73 27.67C25.66 27.67 25.58 27.67 25.58 27.74C25.58 27.74 25.52
				27.68 25.51 27.67C25.36 27.67 25.51 27.89 25.36 27.89C25 27.6 24.49 28.03 24.05 27.89C23.83 28.17
				23.47 27.89 23.18 28.1C23.1 28.1 23.03 28.1 23.03 28.03C22.67 28.03 22.23 28.03 21.86 28.17C22.16
				28.1 22.45 28.03 22.59 28.32C22.6 28.31 22.66 28.25 22.67 28.24C22.88 28.39 23.1 28.17 23.25
				28.46C23.47 28.53 23.69 28.53 23.91 28.53C23.98 28.82 24.2 28.89 24.49 28.82C24.56 28.89 24.56 28.89
				24.63 28.96C24.63 28.97 24.57 29.03 24.56 29.03C24.63 29.03 24.63 29.03 24.71 29.03C24.78 29.1 24.78
				29.1 24.85 29.18C24.86 29.17 24.92 29.11 24.93 29.1C25 29.18 25.15 29.18 25.22 29.32C25.22 29.39
				25.22 29.39 25.22 29.46C25.44 29.53 25.58 29.68 25.73 29.75C25.73 29.82 25.73 29.89 25.73 29.96C25.8
				30.11 26.02 30.11 26.09 30.25C26.02 30.61 26.53 30.68 26.68 30.97C26.67 30.97 26.61 31.03 26.6
				31.04C26.9 31.25 27.11 31.54 27.41 31.75C27.48 31.9 27.62 31.97 27.62 32.11C27.7 32.11 27.77 32.11
				27.77 32.18C27.77 32.26 27.77 32.26 27.77 32.33C27.77 32.4 27.7 32.4 27.62 32.4C27.55 32.26 27.41
				32.26 27.33 32.18C27.26 32.18 27.26 32.18 27.19 32.18C27.11 32.11 27.11 32.11 27.04 32.04C26.75
				31.97 26.46 31.9 26.24 31.68C26.09 31.68 25.95 31.68 25.87 31.54C25.8 31.54 25.8 31.54 25.73
				31.54C25.66 31.4 25.51 31.4 25.44 31.33C25.36 31.33 25.29 31.33 25.29 31.4C25.07 31.25 24.85 31.18
				24.63 31.04C24.63 31.11 24.63 31.18 24.56 31.18C24.49 31.04 24.27 31.04 24.2 30.82C24.12 30.82 24.05
				30.82 24.05 30.9C23.76 30.75 23.61 30.39 23.25 30.32C23.1 30.11 22.96 29.82 22.67 29.82C22.52 29.68
				22.45 29.46 22.3 29.32C22.23 29.32 22.23 29.32 22.16 29.32C22.08 29.25 22.08 29.18 22.08 29.1C21.86
				29.03 21.86 28.89 21.72 28.75C21.5 29.68 21.72 30.61 21.64 31.54C21.79 31.75 21.79 31.97 21.79
				32.18C21.72 32.4 21.57 32.61 21.35 32.76C21.28 32.76 21.28 32.76 21.21 32.76C21.13 32.83 21.13 32.83
				21.06 32.9C20.92 32.83 21.06 32.69 20.92 32.61C20.92 32.26 20.99 31.83 20.92 31.47C21.13 31.33 20.92
				31.11 21.06 30.97C21.06 30.25 21.06 29.46 21.06 28.75C20.99 28.75 20.99 28.75 20.92 28.75C20.7 28.96
				20.48 29.25 20.33 29.46C20.26 29.46 20.26 29.46 20.19 29.46C20.11 29.68 19.97 29.89 19.82
				30.04C19.75 30.04 19.75 30.04 19.68 30.04C19.75 30.18 19.46 30.11 19.6 30.25C19.46 30.32 19.31 30.47
				19.24 30.61C19.17 30.61 19.09 30.61 19.02 30.61C18.87 30.82 18.8 31.04 18.58 31.11C18.51 31.11 18.44
				31.11 18.44 31.04C18.36 31.18 18.22 31.25 18.14 31.4C17.85 31.25 17.63 31.54 17.34 31.68C17.27 31.68
				17.2 31.68 17.2 31.61C17.05 31.68 17.05 31.68 16.98 31.83C16.69 31.9 16.39 32.04 16.1 32.26C16.03
				32.26 15.96 32.26 15.88 32.26C15.88 32.33 15.88 32.4 15.81 32.4C15.52 32.4 15.3 32.61 15.08
				32.76C15.01 32.76 14.93 32.76 14.93 32.69C14.93 32.47 15.15 32.18 15.37 32.11C15.37 32.04 15.37
				32.04 15.37 31.97C15.59 31.75 15.96 31.54 16.03 31.18C16.18 31.11 16.25 30.97 16.47 30.9C16.47 30.82
				16.47 30.75 16.39 30.75C16.54 30.47 16.9 30.32 16.83 30.04C17.27 29.82 17.42 29.39 17.85 29.25C18
				29.25 17.93 29.03 18.07 29.03C18.14 29.03 18.29 29.03 18.36 29.03C18.51 28.96 18.58 28.82 18.66
				28.75C18.66 28.75 18.72 28.81 18.73 28.82C18.8 28.67 18.95 28.82 19.02 28.67C19.03 28.68 19.09 28.74
				19.09 28.75C19.24 28.67 19.24 28.67 19.31 28.53C19.53 28.46 19.75 28.46 19.97 28.46C19.97 28.39
				19.97 28.32 20.04 28.32C20.26 28.32 20.41 28.32 20.62 28.32C20.48 28.1 20.26 28.32 20.11 28.17C20.11
				28.18 20.05 28.24 20.04 28.24C19.97 28.24 19.82 28.32 19.75 28.17C19.68 28.17 19.6 28.17 19.6
				28.24C19.46 28.24 19.24 28.32 19.09 28.17C19.09 28.18 19.06 28.2 19.02 28.24C19.01 28.24 18.95 28.18
				18.95 28.17C18.9 28.22 18.88 28.24 18.87 28.24C18.8 28.24 18.73 28.24 18.66 28.17C18.66 28.24 18.58
				28.24 18.51 28.24C18.44 28.24 18.44 28.17 18.44 28.1C18.14 28.03 17.93 28.24 17.71 28.03C17.7 28.04
				17.64 28.09 17.63 28.1C17.56 28.1 17.49 28.1 17.49 28.03C17.48 28.04 17.42 28.09 17.42 28.1C17.12
				28.17 16.76 27.89 16.47 28.1C16.46 28.1 16.44 28.07 16.39 28.03C16.35 28.07 16.33 28.1 16.32
				28.1C16.1 28.17 15.96 28.03 15.81 27.96C15.59 28.1 15.3 27.96 15.01 28.03C14.94 27.96 14.94 27.96
				14.86 27.89C14.57 27.96 14.28 27.81 14.06 27.96C13.99 27.89 13.91 27.89 13.91 27.74C13.7 27.81 13.33
				27.81 13.18 27.6C13.11 27.6 13.04 27.6 13.04 27.67C12.97 27.67 12.89 27.67 12.89 27.6C12.89 27.6
				12.86 27.63 12.82 27.67C12.78 27.63 12.75 27.6 12.75 27.6C12.68 27.6 12.6 27.6 12.6 27.67C12.53
				27.53 12.31 27.6 12.31 27.38C12.24 27.38 12.16 27.38 12.16 27.46C12.09 27.46 12.02 27.46 11.95
				27.38C11.87 27.38 11.8 27.38 11.8 27.46C11.73 27.38 11.65 27.38 11.58 27.38C11.51 27.38 11.51 27.31
				11.51 27.24C11.22 27.17 10.92 27.46 10.78 27.17C10.34 27.1 9.98 27.24 9.54 27.24C9.47 27.38 9.32
				27.46 9.17 27.31C9.25 27.17 9.47 27.1 9.54 26.96C9.61 26.96 9.61 26.96 9.69 26.96C9.76 26.81 9.9
				26.74 10.05 26.74C10.12 26.6 10.34 26.6 10.41 26.45C10.49 26.45 10.49 26.45 10.56 26.45C10.63 26.38
				10.63 26.38 10.71 26.31C10.71 26.32 10.77 26.37 10.78 26.38C10.85 26.31 10.92 26.31 11 26.31C10.99
				26.3 10.93 26.25 10.92 26.24C10.92 26.17 10.92 26.1 11 26.1C11.07 26.1 11.22 26.17 11.29 26.02C11.3
				26.03 11.35 26.09 11.36 26.1C11.58 26.02 11.73 25.95 11.87 25.74C12.02 25.95 12.24 25.67 12.46
				25.74C12.53 25.67 12.53 25.67 12.6 25.59C12.89 25.59 13.18 25.59 13.48 25.59C13.48 25.52 13.48 25.45
				13.55 25.38C13.77 25.52 14.06 25.52 14.35 25.52C14.43 25.45 14.43 25.45 14.5 25.38C14.5 25.38 14.53
				25.41 14.57 25.45C14.61 25.41 14.64 25.38 14.64 25.38C14.72 25.45 14.72 25.45 14.79 25.52C14.8 25.52
				14.85 25.46 14.86 25.45C14.93 25.59 15.08 25.52 15.23 25.52C15.23 25.45 15.23 25.38 15.3 25.38C15.59
				25.45 15.81 25.52 16.1 25.67C16.25 25.45 16.47 25.59 16.61 25.74C16.69 25.74 16.69 25.74 16.76
				25.74C16.83 25.88 16.98 25.88 17.12 25.81C17.27 25.95 17.56 26.02 17.63 26.24C18 26.17 18.22 26.53
				18.44 26.74C18.51 26.67 18.51 26.67 18.58 26.6C18.8 26.88 19.09 27.24 19.46 27.17C19.53 27.24 19.53
				27.24 19.6 27.31C19.68 27.31 19.68 27.31 19.75 27.31C19.97 27.53 20.26 27.6 20.55 27.74C20.33 27.46
				20.11 27.17 19.75 27.03C19.53 26.74 19.24 26.6 19.09 26.31C18.95 26.31 18.87 26.24 18.8 26.17C18.87
				26.02 18.65 26.1 18.58 25.95C18.58 25.88 18.58 25.88 18.58 25.81C18.29 25.67 18.07 25.52 17.85
				25.38C17.85 25.31 17.85 25.24 17.92 25.24C17.71 25.09 17.49 25.02 17.34 24.88C17.34 24.52 16.9 24.45
				16.69 24.23C16.69 24.16 16.69 24.16 16.69 24.09C16.47 23.88 16.18 23.73 16.03 23.52C15.88 23.45
				16.25 23.45 16.03 23.37C15.88 23.09 15.59 22.94 15.37 22.66C15.45 22.3 15.01 22.16 14.86 21.94C14.86
				21.87 14.86 21.87 14.86 21.8C14.79 21.8 14.72 21.8 14.72 21.73C14.79 21.51 14.64 21.44 14.5
				21.3C14.5 21.22 14.5 21.22 14.5 21.15C14.28 21.08 14.28 20.87 14.13 20.72C14.13 20.65 14.13 20.58
				14.21 20.58C14.06 20.36 13.91 20.15 13.77 19.93C13.84 19.86 13.84 19.79 13.84 19.72C13.62 19.5 13.55
				19.22 13.4 18.93C13.41 18.92 13.47 18.87 13.48 18.86C13.55 18.5 13.04 18.14 13.26 17.79C13.18 17.71
				13.18 17.71 13.11 17.64C13.11 17.57 13.11 17.57 13.11 17.5C13.04 17.5 12.97 17.5 12.97 17.43C13.04
				17.21 12.82 17.07 12.75 16.93C12.82 16.85 12.82 16.85 12.89 16.78C12.75 16.5 12.67 16.14 12.46
				15.85C12.46 15.78 12.46 15.71 12.53 15.64C12.38 15.35 12.31 14.99 12.16 14.71C12.17 14.7 12.23 14.64
				12.24 14.63C12.09 14.56 12.24 14.42 12.09 14.35C12.1 14.34 12.16 14.28 12.16 14.28C12.09 14.2 12.09
				14.13 12.09 14.06C12.07 14.03 11.99 13.87 11.85 13.58C11.86 13.59 11.95 13.6 12.09 13.63C12.32 13.86
				12.45 13.99 12.48 14.01C12.75 14.22 12.91 14.33 12.94 14.35C13.02 14.35 13.02 14.35 13.11 14.35C13.2
				14.52 13.37 14.52 13.46 14.61C13.54 14.95 13.8 15.12 14.06 15.37C14.41 15.63 14.75 16.05 15.1
				16.31C15.1 16.56 15.36 16.65 15.36 16.82"
						id={svgId2}
					/>
					<path
						d="M15.23 16.71C15.45 16.85 15.52 17.07 15.66 17.28C15.74 17.28 15.74 17.28 15.81 17.28C15.88 17.36
				15.96 17.43 16.03 17.5C16.03 17.57 16.03 17.57 16.03 17.64C16.1 17.64 16.18 17.64 16.18 17.71C16.18
				17.79 16.18 17.86 16.18 17.93C16.32 18.07 16.47 18 16.54 18.14C16.54 18.22 16.54 18.22 16.54
				18.29C16.61 18.29 16.69 18.29 16.69 18.36C16.61 18.5 16.83 18.57 16.76 18.72C17.12 18.93 17.2 19.29
				17.34 19.65C17.42 19.65 17.42 19.65 17.49 19.65C17.56 19.72 17.56 19.72 17.63 19.79C17.63 20.15
				17.85 20.51 18.14 20.72C18.14 20.8 18.14 20.8 18.14 20.87C18.22 20.94 18.22 20.94 18.29 21.01C18.29
				21.22 18.29 21.37 18.44 21.51C18.44 21.58 18.44 21.65 18.44 21.73C18.58 21.73 18.58 21.8 18.66
				21.87C18.73 22.16 18.73 22.58 19.02 22.73C19.02 22.8 19.02 22.87 19.02 22.94C19.24 23.09 19.09 23.3
				19.24 23.52C19.23 23.52 19.17 23.58 19.17 23.59C19.24 23.66 19.31 23.73 19.38 23.8C19.38 23.95 19.46
				24.09 19.53 24.23C19.52 24.24 19.46 24.3 19.46 24.3C19.46 24.45 19.68 24.3 19.68 24.45C19.75 24.73
				19.82 24.95 19.89 25.24C19.97 25.24 20.04 25.24 20.04 25.31C20.04 25.45 20.04 25.59 20.04
				25.74C20.19 25.81 20.19 25.95 20.19 26.1C20.55 26.53 20.62 27.03 20.92 27.46C20.99 27.24 20.84 26.96
				20.84 26.74C20.7 26.6 20.77 26.38 20.62 26.24C20.63 26.23 20.69 26.17 20.7 26.17C20.48 25.95 20.55
				25.52 20.48 25.24C20.41 25.24 20.33 25.24 20.33 25.16C20.48 24.81 20.19 24.38 20.04 24.02C20.04
				23.95 20.04 23.88 20.11 23.88C20.11 23.8 20.11 23.73 20.04 23.73C20.05 23.72 20.11 23.67 20.11
				23.66C20.04 23.59 20.04 23.59 19.97 23.52C19.97 23.3 19.89 23.16 19.75 23.01C19.75 22.87 19.89 22.8
				19.82 22.66C19.75 22.58 19.75 22.58 19.68 22.51C19.6 22.3 19.68 22.01 19.46 21.8C19.46 21.73 19.46
				21.65 19.53 21.58C19.38 21.22 19.31 20.8 19.24 20.44C19.38 20.22 19.24 19.86 19.09 19.65C19.17 19.43
				18.87 19.15 19.24 19.08C19.24 18.86 19.17 18.72 19.09 18.57C19.02 18.36 19.17 18.07 19.02
				17.86C19.09 17.79 19.17 17.79 19.24 17.79C19.24 17.64 19.31 17.43 19.17 17.28C19.17 17.28 19.23
				17.22 19.24 17.21C19.17 17 19.09 16.71 19.31 16.57C19.31 16.5 19.31 16.43 19.24 16.43C19.46 16.14
				19.17 15.78 19.53 15.64C19.53 15.35 19.53 15.06 19.53 14.78C19.53 14.71 19.6 14.71 19.68 14.71C19.6
				14.42 19.75 14.2 19.68 13.92C19.75 13.85 19.75 13.7 19.89 13.63C19.9 13.64 19.96 13.7 19.97
				13.7C19.89 13.34 20.04 13.06 20.04 12.7C20.11 12.63 20.19 12.63 20.26 12.63C20.26 12.56 20.26 12.48
				20.19 12.48C20.33 12.34 20.33 12.2 20.33 11.98C20.48 11.84 20.55 11.63 20.62 11.41C20.62 11.34 20.62
				11.27 20.55 11.27C20.7 11.27 20.55 11.05 20.7 11.05C20.69 11.05 20.63 10.99 20.62 10.98C20.77 10.69
				20.62 10.26 20.84 9.98C20.84 9.91 20.84 9.83 20.77 9.83C20.92 9.62 20.77 9.33 20.77 9.12C20.78 9.12
				20.9 9.12 20.92 9.12C21.06 9.19 20.92 9.33 21.06 9.4C21.06 9.48 21.13 9.62 20.99 9.69C21.21 9.98
				21.06 10.34 21.28 10.55C21.28 10.69 21.21 10.84 21.35 10.98C21.35 10.99 21.29 11.05 21.28
				11.05C21.35 11.12 21.35 11.12 21.43 11.2C21.42 11.2 21.36 11.26 21.35 11.27C21.43 11.41 21.43 11.41
				21.57 11.48C21.56 11.49 21.51 11.55 21.5 11.55C21.72 11.77 21.64 12.05 21.72 12.27C21.79 12.27 21.86
				12.27 21.86 12.34C21.86 12.41 21.86 12.48 21.94 12.56C21.93 12.56 21.87 12.62 21.86 12.63C22.01
				12.84 21.86 13.13 22.16 13.27C22.16 13.56 22.23 13.85 22.16 14.13C22.23 14.13 22.23 14.13 22.3
				14.13C22.45 14.49 22.3 14.85 22.37 15.21C22.45 15.21 22.52 15.21 22.52 15.28C22.52 15.49 22.59 15.71
				22.67 15.85C22.45 16 22.74 16.21 22.67 16.43C22.74 16.5 22.74 16.5 22.81 16.57C22.81 16.64 22.81
				16.71 22.74 16.78C22.88 17.07 22.67 17.43 22.74 17.71C22.81 17.71 22.88 17.71 22.88 17.79C22.88
				17.93 22.96 18.07 22.81 18.22C22.82 18.22 22.88 18.28 22.88 18.29C22.74 18.36 22.88 18.5 22.74
				18.57C22.74 19.15 22.88 19.72 22.59 20.22C22.67 20.29 22.67 20.29 22.74 20.36C22.74 20.72 22.67
				21.01 22.52 21.3C22.67 21.58 22.59 22.01 22.37 22.23C22.59 22.58 22.23 22.94 22.3 23.3C22.3 23.37
				22.23 23.37 22.16 23.37C22.23 23.45 22.23 23.45 22.3 23.52C22.3 23.59 22.3 23.59 22.3 23.66C22.3
				23.73 22.23 23.73 22.16 23.73C22.16 23.8 22.16 23.88 22.08 23.95C22.09 23.95 22.15 24.01 22.16
				24.02C22.16 24.09 22.08 24.09 22.01 24.09C22.01 24.3 21.86 24.45 22.01 24.66C22.01 24.73 21.94 24.73
				21.86 24.73C21.86 24.95 21.86 25.24 21.72 25.45C21.72 25.46 21.78 25.52 21.79 25.52C21.79 25.74
				21.72 25.95 21.64 26.1C21.65 26.1 21.71 26.16 21.72 26.17C21.5 26.38 21.79 26.6 21.57 26.81C21.57
				26.88 21.57 26.96 21.64 26.96C21.5 27.03 21.57 27.17 21.57 27.31C21.57 27.24 21.57 27.24 21.57
				27.17C21.64 27.1 21.64 27.1 21.72 27.03C21.79 26.6 21.94 26.17 22.23 25.88C22.16 25.67 22.45 25.45
				22.23 25.31C22.37 25.09 22.52 24.95 22.45 24.73C22.52 24.66 22.52 24.66 22.59 24.59C22.59 24.45
				22.59 24.38 22.59 24.23C22.67 24.16 22.74 24.16 22.81 24.16C22.81 24.02 22.81 23.88 22.81
				23.73C22.88 23.66 22.88 23.52 23.03 23.45C23.03 23.3 23.03 23.16 23.03 23.01C23.18 22.87 23.18 22.73
				23.18 22.51C23.25 22.44 23.32 22.44 23.4 22.44C23.39 22.43 23.33 22.38 23.32 22.37C23.47 22.23 23.4
				22.01 23.54 21.87C23.53 21.86 23.48 21.8 23.47 21.8C23.54 21.65 23.54 21.51 23.76 21.44C23.91 21.08
				23.83 20.65 24.05 20.36C24.12 20.36 24.12 20.36 24.2 20.36C24.2 20.22 24.27 20.22 24.34 20.15C24.34
				19.86 24.42 19.58 24.56 19.36C24.63 19.36 24.63 19.36 24.71 19.36C24.85 19 24.93 18.65 25.22
				18.43C25.22 18.22 25.44 18.07 25.44 17.86C25.73 17.64 26.02 17.36 26.02 17C26.09 16.85 26.24 16.93
				26.31 16.93C26.31 16.78 26.53 16.71 26.53 16.5C26.82 16.28 27.11 15.92 27.41 15.71C27.62 15.49 27.84
				15.35 27.92 15.06C27.99 14.99 28.14 14.99 28.21 14.85C28.28 14.85 28.28 14.85 28.35 14.85C28.65
				14.49 29.01 14.28 29.38 13.99C29.45 13.85 29.52 13.63 29.59 13.49C29.67 13.49 29.74 13.49 29.74
				13.56C29.74 14.28 29.45 14.92 29.38 15.64C29.23 15.64 29.38 15.85 29.23 15.85C29.23 15.92 29.23 16
				29.23 16.07C29.16 16.14 29.16 16.14 29.08 16.21C29.08 16.28 29.08 16.28 29.08 16.35C29.16 16.35
				29.23 16.35 29.23 16.43C29.01 16.71 28.94 17 28.79 17.28C28.86 17.36 28.86 17.43 28.86 17.5C28.72
				17.5 28.86 17.71 28.72 17.71C28.72 18 28.57 18.22 28.5 18.43C28.57 18.43 28.65 18.43 28.65 18.5C28.5
				18.72 28.43 18.93 28.35 19.22C28.35 19.29 28.28 19.29 28.21 19.29C28.21 19.36 28.21 19.43 28.28
				19.43C28.21 19.65 28.14 19.93 27.92 20.15C27.99 20.22 27.99 20.29 27.99 20.36C27.84 20.44 27.77
				20.58 27.77 20.72C27.7 20.79 27.55 20.94 27.48 21.01C27.55 21.3 27.19 21.51 27.11 21.8C27.11 21.87
				27.04 21.87 26.97 21.87C26.82 22.08 26.82 22.3 26.68 22.51C26.46 22.66 26.31 22.8 26.17 23.01C26.24
				23.01 26.24 23.09 26.24 23.16C26.02 23.45 25.58 23.59 25.58 23.95C25.36 24.02 25.22 24.23 25
				24.3C25.07 24.52 24.85 24.66 24.71 24.81C24.63 24.81 24.63 24.81 24.56 24.81C24.49 24.95 24.34 24.95
				24.42 25.16C24.2 25.38 23.83 25.45 23.76 25.81C23.1 26.31 22.52 26.96 21.94 27.6C22.01 27.6 22.01
				27.6 22.08 27.6C22.16 27.53 22.16 27.53 22.23 27.46C22.3 27.46 22.3 27.46 22.37 27.46C22.52 27.31
				22.67 27.1 22.96 27.1C22.96 27.03 22.96 26.96 23.03 26.96C23.1 26.96 23.18 26.96 23.25 26.96C23.4
				26.81 23.61 26.67 23.76 26.45C23.83 26.45 23.91 26.45 23.98 26.45C24.2 26.31 24.34 26.17 24.49
				25.95C24.56 25.95 24.63 25.95 24.71 25.95C24.93 25.74 25.15 25.45 25.51 25.52C25.58 25.45 25.58
				25.45 25.66 25.38C25.73 25.38 25.73 25.38 25.8 25.38C25.8 25.31 25.8 25.24 25.87 25.24C25.88 25.24
				25.9 25.26 25.95 25.31C25.99 25.26 26.02 25.24 26.02 25.24C26.09 25.24 26.17 25.24 26.17 25.31C26.31
				25.31 26.39 25.16 26.53 25.24C26.68 25.09 26.82 25.02 27.04 25.02C27.11 25.09 27.11 25.09 27.19
				25.16C27.41 25.02 27.77 24.95 28.06 25.09C28.07 25.09 28.13 25.03 28.14 25.02C28.21 25.02 28.28
				25.02 28.28 25.09C28.35 24.95 28.65 25.09 28.72 24.95C29.01 25.24 29.38 25.02 29.74 25.09C29.96
				25.38 30.4 25.09 30.69 25.31C30.61 25.31 30.61 25.38 30.61 25.45C30.91 25.59 31.13 25.52 31.42
				25.52C31.49 25.59 31.49 25.67 31.49 25.74C32 25.81 32.37 26.17 32.88 26.31C32.95 26.45 33.1 26.45
				33.17 26.53C33.17 26.67 33.39 26.6 33.31 26.74C33.31 26.81 33.24 26.81 33.17 26.81C33.1 26.74 33.1
				26.74 33.02 26.67C33.01 26.68 32.96 26.73 32.95 26.74C32.88 26.6 32.58 26.74 32.51 26.6C32.5 26.6
				32.45 26.66 32.44 26.67C32.22 26.53 32 26.6 31.78 26.53C31.71 26.6 31.64 26.67 31.56 26.74C31.56
				26.73 31.5 26.68 31.49 26.67C31.13 26.81 30.69 26.74 30.32 26.96C30.25 26.96 30.18 26.96 30.11
				26.88C30.11 26.96 30.11 27.03 30.03 27.1C30.02 27.09 29.97 27.03 29.96 27.03C29.74 27.17 29.45 27.17
				29.23 27.17C29.16 27.31 28.94 27.24 28.79 27.38C28.79 27.38 28.73 27.32 28.72 27.31C28.65 27.38
				28.57 27.38 28.5 27.38C28.5 27.46 28.5 27.53 28.43 27.53C28.28 27.53 28.14 27.6 27.99 27.46C27.98
				27.46 27.96 27.49 27.92 27.53C27.91 27.52 27.89 27.5 27.84 27.46C27.8 27.5 27.78 27.52 27.77
				27.53C27.73 27.49 27.7 27.46 27.7 27.46C27.62 27.53 27.55 27.53 27.48 27.53C27.48 27.6 27.48 27.67
				27.41 27.67C26.82 27.6 26.31 27.89 25.73 27.67C25.66 27.67 25.58 27.67 25.58 27.74C25.58 27.74 25.52
				27.68 25.51 27.67C25.36 27.67 25.51 27.89 25.36 27.89C25 27.6 24.49 28.03 24.05 27.89C23.83 28.17
				23.47 27.89 23.18 28.1C23.1 28.1 23.03 28.1 23.03 28.03C22.67 28.03 22.23 28.03 21.86 28.17C22.16
				28.1 22.45 28.03 22.59 28.32C22.6 28.31 22.66 28.25 22.67 28.24C22.88 28.39 23.1 28.17 23.25
				28.46C23.47 28.53 23.69 28.53 23.91 28.53C23.98 28.82 24.2 28.89 24.49 28.82C24.56 28.89 24.56 28.89
				24.63 28.96C24.63 28.97 24.57 29.03 24.56 29.03C24.63 29.03 24.63 29.03 24.71 29.03C24.78 29.1 24.78
				29.1 24.85 29.18C24.86 29.17 24.92 29.11 24.93 29.1C25 29.18 25.15 29.18 25.22 29.32C25.22 29.39
				25.22 29.39 25.22 29.46C25.44 29.53 25.58 29.68 25.73 29.75C25.73 29.82 25.73 29.89 25.73 29.96C25.8
				30.11 26.02 30.11 26.09 30.25C26.02 30.61 26.53 30.68 26.68 30.97C26.67 30.97 26.61 31.03 26.6
				31.04C26.9 31.25 27.11 31.54 27.41 31.75C27.48 31.9 27.62 31.97 27.62 32.11C27.7 32.11 27.77 32.11
				27.77 32.18C27.77 32.26 27.77 32.26 27.77 32.33C27.77 32.4 27.7 32.4 27.62 32.4C27.55 32.26 27.41
				32.26 27.33 32.18C27.26 32.18 27.26 32.18 27.19 32.18C27.11 32.11 27.11 32.11 27.04 32.04C26.75
				31.97 26.46 31.9 26.24 31.68C26.09 31.68 25.95 31.68 25.87 31.54C25.8 31.54 25.8 31.54 25.73
				31.54C25.66 31.4 25.51 31.4 25.44 31.33C25.36 31.33 25.29 31.33 25.29 31.4C25.07 31.25 24.85 31.18
				24.63 31.04C24.63 31.11 24.63 31.18 24.56 31.18C24.49 31.04 24.27 31.04 24.2 30.82C24.12 30.82 24.05
				30.82 24.05 30.9C23.76 30.75 23.61 30.39 23.25 30.32C23.1 30.11 22.96 29.82 22.67 29.82C22.52 29.68
				22.45 29.46 22.3 29.32C22.23 29.32 22.23 29.32 22.16 29.32C22.08 29.25 22.08 29.18 22.08 29.1C21.86
				29.03 21.86 28.89 21.72 28.75C21.5 29.68 21.72 30.61 21.64 31.54C21.79 31.75 21.79 31.97 21.79
				32.18C21.72 32.4 21.57 32.61 21.35 32.76C21.28 32.76 21.28 32.76 21.21 32.76C21.13 32.83 21.13 32.83
				21.06 32.9C20.92 32.83 21.06 32.69 20.92 32.61C20.92 32.26 20.99 31.83 20.92 31.47C21.13 31.33 20.92
				31.11 21.06 30.97C21.06 30.25 21.06 29.46 21.06 28.75C20.99 28.75 20.99 28.75 20.92 28.75C20.7 28.96
				20.48 29.25 20.33 29.46C20.26 29.46 20.26 29.46 20.19 29.46C20.11 29.68 19.97 29.89 19.82
				30.04C19.75 30.04 19.75 30.04 19.68 30.04C19.75 30.18 19.46 30.11 19.6 30.25C19.46 30.32 19.31 30.47
				19.24 30.61C19.17 30.61 19.09 30.61 19.02 30.61C18.87 30.82 18.8 31.04 18.58 31.11C18.51 31.11 18.44
				31.11 18.44 31.04C18.36 31.18 18.22 31.25 18.14 31.4C17.85 31.25 17.63 31.54 17.34 31.68C17.27 31.68
				17.2 31.68 17.2 31.61C17.05 31.68 17.05 31.68 16.98 31.83C16.69 31.9 16.39 32.04 16.1 32.26C16.03
				32.26 15.96 32.26 15.88 32.26C15.88 32.33 15.88 32.4 15.81 32.4C15.52 32.4 15.3 32.61 15.08
				32.76C15.01 32.76 14.93 32.76 14.93 32.69C14.93 32.47 15.15 32.18 15.37 32.11C15.37 32.04 15.37
				32.04 15.37 31.97C15.59 31.75 15.96 31.54 16.03 31.18C16.18 31.11 16.25 30.97 16.47 30.9C16.47 30.82
				16.47 30.75 16.39 30.75C16.54 30.47 16.9 30.32 16.83 30.04C17.27 29.82 17.42 29.39 17.85 29.25C18
				29.25 17.93 29.03 18.07 29.03C18.14 29.03 18.29 29.03 18.36 29.03C18.51 28.96 18.58 28.82 18.66
				28.75C18.66 28.75 18.72 28.81 18.73 28.82C18.8 28.67 18.95 28.82 19.02 28.67C19.03 28.68 19.09 28.74
				19.09 28.75C19.24 28.67 19.24 28.67 19.31 28.53C19.53 28.46 19.75 28.46 19.97 28.46C19.97 28.39
				19.97 28.32 20.04 28.32C20.26 28.32 20.41 28.32 20.62 28.32C20.48 28.1 20.26 28.32 20.11 28.17C20.11
				28.18 20.05 28.24 20.04 28.24C19.97 28.24 19.82 28.32 19.75 28.17C19.68 28.17 19.6 28.17 19.6
				28.24C19.46 28.24 19.24 28.32 19.09 28.17C19.09 28.18 19.06 28.2 19.02 28.24C19.01 28.24 18.95 28.18
				18.95 28.17C18.9 28.22 18.88 28.24 18.87 28.24C18.8 28.24 18.73 28.24 18.66 28.17C18.66 28.24 18.58
				28.24 18.51 28.24C18.44 28.24 18.44 28.17 18.44 28.1C18.14 28.03 17.93 28.24 17.71 28.03C17.7 28.04
				17.64 28.09 17.63 28.1C17.56 28.1 17.49 28.1 17.49 28.03C17.48 28.04 17.42 28.09 17.42 28.1C17.12
				28.17 16.76 27.89 16.47 28.1C16.46 28.1 16.44 28.07 16.39 28.03C16.35 28.07 16.33 28.1 16.32
				28.1C16.1 28.17 15.96 28.03 15.81 27.96C15.59 28.1 15.3 27.96 15.01 28.03C14.94 27.96 14.94 27.96
				14.86 27.89C14.57 27.96 14.28 27.81 14.06 27.96C13.99 27.89 13.91 27.89 13.91 27.74C13.7 27.81 13.33
				27.81 13.18 27.6C13.11 27.6 13.04 27.6 13.04 27.67C12.97 27.67 12.89 27.67 12.89 27.6C12.89 27.6
				12.86 27.63 12.82 27.67C12.78 27.63 12.75 27.6 12.75 27.6C12.68 27.6 12.6 27.6 12.6 27.67C12.53
				27.53 12.31 27.6 12.31 27.38C12.24 27.38 12.16 27.38 12.16 27.46C12.09 27.46 12.02 27.46 11.95
				27.38C11.87 27.38 11.8 27.38 11.8 27.46C11.73 27.38 11.65 27.38 11.58 27.38C11.51 27.38 11.51 27.31
				11.51 27.24C11.22 27.17 10.92 27.46 10.78 27.17C10.34 27.1 9.98 27.24 9.54 27.24C9.47 27.38 9.32
				27.46 9.17 27.31C9.25 27.17 9.47 27.1 9.54 26.96C9.61 26.96 9.61 26.96 9.69 26.96C9.76 26.81 9.9
				26.74 10.05 26.74C10.12 26.6 10.34 26.6 10.41 26.45C10.49 26.45 10.49 26.45 10.56 26.45C10.63 26.38
				10.63 26.38 10.71 26.31C10.71 26.32 10.77 26.37 10.78 26.38C10.85 26.31 10.92 26.31 11 26.31C10.99
				26.3 10.93 26.25 10.92 26.24C10.92 26.17 10.92 26.1 11 26.1C11.07 26.1 11.22 26.17 11.29 26.02C11.3
				26.03 11.35 26.09 11.36 26.1C11.58 26.02 11.73 25.95 11.87 25.74C12.02 25.95 12.24 25.67 12.46
				25.74C12.53 25.67 12.53 25.67 12.6 25.59C12.89 25.59 13.18 25.59 13.48 25.59C13.48 25.52 13.48 25.45
				13.55 25.38C13.77 25.52 14.06 25.52 14.35 25.52C14.43 25.45 14.43 25.45 14.5 25.38C14.5 25.38 14.53
				25.41 14.57 25.45C14.61 25.41 14.64 25.38 14.64 25.38C14.72 25.45 14.72 25.45 14.79 25.52C14.8 25.52
				14.85 25.46 14.86 25.45C14.93 25.59 15.08 25.52 15.23 25.52C15.23 25.45 15.23 25.38 15.3 25.38C15.59
				25.45 15.81 25.52 16.1 25.67C16.25 25.45 16.47 25.59 16.61 25.74C16.69 25.74 16.69 25.74 16.76
				25.74C16.83 25.88 16.98 25.88 17.12 25.81C17.27 25.95 17.56 26.02 17.63 26.24C18 26.17 18.22 26.53
				18.44 26.74C18.51 26.67 18.51 26.67 18.58 26.6C18.8 26.88 19.09 27.24 19.46 27.17C19.53 27.24 19.53
				27.24 19.6 27.31C19.68 27.31 19.68 27.31 19.75 27.31C19.97 27.53 20.26 27.6 20.55 27.74C20.33 27.46
				20.11 27.17 19.75 27.03C19.53 26.74 19.24 26.6 19.09 26.31C18.95 26.31 18.87 26.24 18.8 26.17C18.87
				26.02 18.65 26.1 18.58 25.95C18.58 25.88 18.58 25.88 18.58 25.81C18.29 25.67 18.07 25.52 17.85
				25.38C17.85 25.31 17.85 25.24 17.92 25.24C17.71 25.09 17.49 25.02 17.34 24.88C17.34 24.52 16.9 24.45
				16.69 24.23C16.69 24.16 16.69 24.16 16.69 24.09C16.47 23.88 16.18 23.73 16.03 23.52C15.88 23.45
				16.25 23.45 16.03 23.37C15.88 23.09 15.59 22.94 15.37 22.66C15.45 22.3 15.01 22.16 14.86 21.94C14.86
				21.87 14.86 21.87 14.86 21.8C14.79 21.8 14.72 21.8 14.72 21.73C14.79 21.51 14.64 21.44 14.5
				21.3C14.5 21.22 14.5 21.22 14.5 21.15C14.28 21.08 14.28 20.87 14.13 20.72C14.13 20.65 14.13 20.58
				14.21 20.58C14.06 20.36 13.91 20.15 13.77 19.93C13.84 19.86 13.84 19.79 13.84 19.72C13.62 19.5 13.55
				19.22 13.4 18.93C13.41 18.92 13.47 18.87 13.48 18.86C13.55 18.5 13.04 18.14 13.26 17.79C13.18 17.71
				13.18 17.71 13.11 17.64C13.11 17.57 13.11 17.57 13.11 17.5C13.04 17.5 12.97 17.5 12.97 17.43C13.04
				17.21 12.82 17.07 12.75 16.93C12.82 16.85 12.82 16.85 12.89 16.78C12.75 16.5 12.67 16.14 12.46
				15.85C12.46 15.78 12.46 15.71 12.53 15.64C12.38 15.35 12.31 14.99 12.16 14.71C12.17 14.7 12.23 14.64
				12.24 14.63C12.09 14.56 12.24 14.42 12.09 14.35C12.1 14.34 12.16 14.28 12.16 14.28C12.09 14.2 12.09
				14.13 12.09 14.06C12.07 14.03 11.99 13.87 11.85 13.58C11.86 13.59 11.95 13.6 12.09 13.63C12.32 13.86
				12.45 13.99 12.48 14.01C12.75 14.22 12.91 14.33 12.94 14.35C13.02 14.35 13.02 14.35 13.11 14.35C13.2
				14.52 13.37 14.52 13.46 14.61C13.54 14.95 13.8 15.12 14.06 15.37C14.41 15.63 14.75 16.05 15.1
				16.31C15.1 16.56 15.36 16.65 15.36 16.82"
						id={svgId3}
					/>
				</defs>
				<use xlinkHref={`#${svgId1}`} />
				<use fillOpacity="0" strokeOpacity="0" xlinkHref={`#${svgId1}`} />
				<use xlinkHref={`#${svgId2}`} />
				<use fillOpacity="0" strokeOpacity="0" xlinkHref={`#${svgId2}`} />
				<g>
					<use fillOpacity="0" xlinkHref={`#${svgId3}`} />
					<use fillOpacity="0" strokeOpacity="0" xlinkHref={`#${svgId3}`} />
				</g>
			</svg>
		</StrainIconWrapper>
	)
}

StrainIcon.propTypes = {
	color: propTypes.string,
	height: propTypes.number,
	width: propTypes.number,
}
StrainIcon.defaultProps = {
	color: COLORS.BLACK,
	height: 40,
	width: 40,
}

export default StrainIcon
