import React from 'react'
import propTypes from 'prop-types'
import { COLORS } from 'utils/styleHelpers'
import { genSVGId } from 'utils/genGuid'

const InventoryConcentrateIcon = ({ width, height, color }) => {
	const svgId1 = genSVGId()
	const svgId2 = genSVGId()
	const svgId3 = genSVGId()
	return (
		<div>
			<svg height={height} viewBox="0 0 40 40" width={width}>
				<defs>
					<path
						d="M21.4 5.26L21.45 5.27L21.5 5.28L21.56 5.3L21.61 5.32L21.66 5.35L21.7 5.38L21.75 5.41L21.79 5.44L21.84 5.48L24.93 8.58L31.48 15.12L34.57 18.22L34.61 18.26L34.65 18.3L34.68 18.35L34.71 18.4L34.73 18.45L34.75 18.5L34.77 18.55L34.79 18.6L34.8 18.66L34.8 18.71L34.81 18.76L34.81 18.82L34.8 18.87L34.8 18.93L34.79 18.98L34.77 19.03L34.75 19.08L34.73 19.14L34.71 19.18L34.68 19.23L34.65 19.28L34.61 19.32L34.57 19.37L29.78 24.15L29.74 24.19L29.7 24.23L29.65 24.26L29.6 24.29L29.55 24.32L29.5 24.34L29.45 24.35L29.4 24.37L29.34 24.38L29.29 24.39L29.24 24.39L29.18 24.39L29.13 24.39L29.07 24.38L29.02 24.37L28.97 24.35L28.92 24.34L28.87 24.32L28.82 24.29L28.77 24.26L28.72 24.23L28.68 24.19L28.63 24.15L25.6 21.11L11 35.81L10.99 35.81L10.98 35.82L10.98 35.83L10.97 35.83L10.96 35.84L10.96 35.85L10.95 35.85L10.94 35.86L10.93 35.87L10.93 35.87L10.92 35.88L10.91 35.88L10.9 35.89L10.9 35.89L10.89 35.9L10.88 35.91L10.87 35.91L10.87 35.92L10.86 35.92L10.85 35.93L10.84 35.93L10.83 35.94L10.82 35.94L3.72 40L0 36.31L4.17 29.27L4.18 29.25L4.18 29.25L4.19 29.24L4.19 29.23L4.2 29.22L4.2 29.22L4.21 29.21L4.21 29.2L4.22 29.19L4.23 29.18L4.23 29.18L4.25 29.16L4.25 29.16L4.26 29.15L4.26 29.14L4.27 29.14L4.27 29.13L4.29 29.12L4.29 29.11L18.89 14.41L15.9 11.42L15.86 11.38L15.83 11.33L15.79 11.29L15.77 11.24L15.74 11.19L15.72 11.14L15.7 11.09L15.69 11.03L15.68 10.98L15.67 10.93L15.66 10.87L15.66 10.82L15.67 10.76L15.68 10.71L15.69 10.66L15.7 10.6L15.72 10.55L15.74 10.5L15.77 10.45L15.79 10.4L15.83 10.36L15.86 10.31L15.9 10.27L20.69 5.48L20.73 5.44L20.78 5.41L20.82 5.38L20.87 5.35L20.92 5.32L20.97 5.3L21.02 5.28L21.07 5.27L21.13 5.26L21.18 5.25L21.24 5.25L21.29 5.25L21.34 5.25L21.4 5.26ZM2.14 35.88L4.14 37.88L9.89 34.55L24.45 19.97L20.04 15.56L5.52 30.18L2.14 35.88Z"
						id={svgId1}
					/>
					<path
						d="M37.94 1.16C37.19 0.41 36.2 0 35.15 0C34.09 0 33.1 0.41 32.35 1.16C31.94 1.57 29.84 3.67 26.08 7.43L32.62 13.97C36.39 10.21 38.48 8.12 38.9 7.7C39.64 6.95 40.05 5.96 40.05 4.91C40.05 3.85 39.64 2.86 38.9 2.11C38.71 1.92 38.04 1.25 37.94 1.16Z"
						id={svgId2}
					/>
					<path
						d="M4.18 37.85L9.87 34.53L24.4 19.94L20.03 15.6L5.53 30.13L2.15 35.85L4.18 37.85Z"
						id={svgId3}
					/>
				</defs>
				<use fill={color} fillOpacity="1" opacity="1" xlinkHref={`#${svgId1}`} />
				<use
					fillOpacity="0"
					opacity="1"
					stroke={color}
					strokeOpacity="0"
					strokeWidth="1"
					xlinkHref={`#${svgId1}`}
				/>
				<use fill={color} fillOpacity="1" opacity="1" xlinkHref={`#${svgId2}`} />
				<use
					fillOpacity="0"
					opacity="1"
					stroke={color}
					strokeOpacity="0"
					strokeWidth="1"
					xlinkHref={`#${svgId2}`}
				/>
				<use fill="#FFF" fillOpacity="1" opacity="1" xlinkHref={`#${svgId3}`} />
				<use
					fillOpacity="0"
					opacity="1"
					stroke={color}
					strokeOpacity="1"
					strokeWidth="0"
					xlinkHref={`#${svgId3}`}
				/>
			</svg>
		</div>
	)
}

InventoryConcentrateIcon.propTypes = {
	color: propTypes.string,
	height: propTypes.number,
	width: propTypes.number,
}
InventoryConcentrateIcon.defaultProps = {
	height: 40,
	width: 40,
	color: COLORS.GREEN,
}
export default InventoryConcentrateIcon
