// externals
import React from 'react'
import PropTypes from 'prop-types'
import { InstantSearch } from 'react-instantsearch/dom'
import algoliasearch from 'algoliasearch/lite'
import styled from '@emotion/styled'
// components
import Search from 'components/Search'
// constants
import { credentials } from 'secrets/algolia-key'

import { COLORS, MQ, SPACERS } from 'utils/styleHelpers'

// imgs

const OmniSearchWrapper = styled.div`
	background: ${COLORS.LIGHT_GRAY};
	border-bottom: 1px solid hsla(0, 0%, 0%, 0.07);
	min-height: 0;
	padding: ${SPACERS.S};
	position: absolute;
	top: ${({ breadCrumbHeight }) => (breadCrumbHeight < 30 ? `90px` : `100px`)};
	width: 100%;
	z-index: 2;
	transition: background 0.5s;
	${MQ.L} {
		background: ${COLORS.GRAY};
		padding: ${SPACERS.M} ${SPACERS.L};
	}
	.dark & {
		background: ${COLORS.BLACK};
	}
`

const { appId, apiKey } = credentials
const searchClient = algoliasearch(appId, apiKey)

const OmniSearch = ({ toggleSearch }) => {
	return (
		<OmniSearchWrapper breadCrumbHeight={window.breadCrumbHeight}>
			<InstantSearch searchClient={searchClient} indexName="dispensaries">
				<Search
					focusOnLoad
					toggleSearch={toggleSearch}
				/>
			</InstantSearch>
		</OmniSearchWrapper>
	)
}
OmniSearch.propTypes = {
	toggleSearch: PropTypes.func
}
export default OmniSearch
