import React from 'react'
import { COLORS } from 'utils/styleHelpers'
const ResetIcon = (
	<svg height="10" viewBox="0 0 20 20" width="10">
		<path
			d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20
				1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17
				7.17-.944.943L0 18.114l.943-.943L8.113 10z"
			id="bqgM1gPgH2"
		/>
		<use fill={COLORS.GREEN} xlinkHref="#bqgM1gPgH2" />
	</svg>
)

export default ResetIcon
