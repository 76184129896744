import React from 'react'
import propTypes from 'prop-types'
import { SearchFilterItemWrapper, SearchFilterLabel } from './styles'

function SearchFilter({ category, color, iconHeight, iconWidth, index, isSelected, onClick }) {
	const handleClick = () => {
		const displayLabel = !category ? index : category
		onClick(displayLabel)
	}

	// categories are for the inventory index, show index name if not a category name
	const displayLabel = !category ? index : category
	const displayText = displayLabel.charAt(0).toUpperCase() + displayLabel.substr(1)
	return (
		<>
			<SearchFilterItemWrapper isSelected={isSelected} onClick={handleClick}>
				<SearchFilterLabel isSelected={isSelected}>{displayText}</SearchFilterLabel>
			</SearchFilterItemWrapper>
		</>
	)
}

SearchFilter.propTypes = {
	category: propTypes.string,
	color: propTypes.string,
	iconHeight: propTypes.number,
	iconWidth: propTypes.number,
	index: propTypes.string,
	isSelected: propTypes.bool,
	onClick: propTypes.func,
}

SearchFilter.defaultProps = {
	iconHeight: 29,
	iconWidth: 29,
	isSelected: false,
	onClick: () => true,
}

export default SearchFilter
