import React from 'react'
import propTypes from 'prop-types'
import { COLORS } from 'utils/styleHelpers'

const ZoomOutIcon = ({ width, height, color }) => (
	<svg height={height} viewBox="0 0 40 40" width={width}>
		<defs>
			<path d="M0 15L40 15L40 25L0 25L0 15Z" id="f50Q58gCJ" />
		</defs>
		<use fill={color} xlinkHref="#f50Q58gCJ" />
		<use fillOpacity="0" stroke={color} strokeWidth="0" xlinkHref="#f50Q58gCJ" />
	</svg>
)

ZoomOutIcon.propTypes = {
	color: propTypes.string,
	height: propTypes.number,
	width: propTypes.number,
}
ZoomOutIcon.defaultProps = {
	color: COLORS.GREEN,
	height: 40,
	width: 40,
}

export default ZoomOutIcon
